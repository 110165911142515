import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Radio, RadioChangeEvent } from 'antd';
import customDayjs, { Dayjs, todayDayjs } from '~plugins/dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ScheduleViewMode, SupportLanguage } from '~common/constants';
import { useAppSelector } from '~hooks';
import { currentLanguageSelector } from '~common/reducers/app.reducer';
import localeJa from 'antd/es/date-picker/locale/ja_JP';
import localeEn from 'antd/es/date-picker/locale/en_US';
import './BookingScheduleHeader.scss';
import { DatePicker } from '../DatePicker/DatePicker';

type Props = {
    defaultValue: Dayjs;
    viewMode: ScheduleViewMode;
    onChangeDate: (value: Dayjs) => void;
    onChangeView: (value: ScheduleViewMode) => void;
};

export const BookingScheduleHeader = ({
    defaultValue,
    viewMode,
    onChangeDate,
    onChangeView,
}: Props) => {
    const currentLang = useAppSelector(currentLanguageSelector);

    const currentDate = useMemo(() => {
        return defaultValue;
    }, [defaultValue]);

    const { t } = useTranslation();

    const types = useMemo(() => {
        return Object.values(ScheduleViewMode).map((type) => {
            return {
                value: type,
                label: t('common.schedule.viewMode.' + type.toLowerCase()),
            };
        });
    }, []);

    const DatePickerLanguage = useMemo(() => {
        if (currentLang === SupportLanguage.EN) return localeEn;
        return localeJa;
    }, [currentLang]);

    const _onChangeType = (e: RadioChangeEvent) => {
        setCurrentViewMode(e.target.value);
    };

    const setCurrentDate = (date: Dayjs) => {
        onChangeDate(date);
    };

    const setCurrentViewMode = (value: ScheduleViewMode) => {
        onChangeView(value);
    };

    const _onChangeDate = (next = true) => {
        switch (viewMode) {
            case ScheduleViewMode.DAY:
                setCurrentDate(currentDate.add(next ? 1 : -1, 'day'));
                break;
            case ScheduleViewMode.WEEK:
                setCurrentDate(currentDate.add(next ? 1 : -1, 'week'));
                break;
            case ScheduleViewMode.MONTH:
                setCurrentDate(currentDate.add(next ? 1 : -1, 'month'));
                break;
        }
    };

    const _onToday = () => {
        setCurrentDate(customDayjs());
    };

    const _onChangeDatePicker = (date: Dayjs | null) => {
        if (date) {
            setCurrentDate(date);
        }
    };

    return (
        <div className="schedule-header-wrapper">
            <div className="time-control">
                <div>
                    <Button onClick={() => _onToday()}>
                        {t('common.schedule.today')}
                    </Button>
                </div>

                <div className="date-select">
                    <div className="arrows">
                        <Button onClick={() => _onChangeDate(false)}>
                            <LeftOutlined />
                        </Button>

                        <DatePicker
                            value={currentDate}
                            defaultValue={todayDayjs}
                            onChange={(date) => _onChangeDatePicker(date)}
                            getPopupContainer={(triggerNode: HTMLElement) =>
                                (triggerNode?.parentNode as HTMLElement) || document.body
                            }
                            locale={DatePickerLanguage}
                        />

                        <Button onClick={() => _onChangeDate(true)}>
                            <RightOutlined />
                        </Button>
                    </div>
                </div>

                <div>
                    <Radio.Group
                        className="type-select"
                        options={types}
                        onChange={_onChangeType}
                        value={viewMode}
                        optionType="button"
                    />
                </div>
            </div>
        </div>
    );
};
