import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _, { cloneDeep, forEach } from 'lodash';
import { ScheduleViewMode } from '~common/constants';
import { IBodyResponse } from '~common/interfaces';
import {
    getStartDateAndEndDate,
    getStartDateAndEndDateByDay,
} from '~features/inventory/helper';
import { inventoryService } from '~features/inventory/services/inventory.service';
import customDayjs, { parseDate } from '~plugins/dayjs';
import { AppState } from '~plugins/redux-toolkit/store';
import { RoomBookingItemBookingStatus } from '../constants';
import {
    buildStatisticsByDate,
    convertBooking,
    convertRoomBookingStaticItemToRoomBookingSchedule,
    getBookingPlan,
    getDayColumns,
    getHoursColumns,
    parseBookingList,
    parseScheduleResponse,
} from '../helper';
import {
    IAddBookingPayload,
    IBookingPlanItem,
    ICheckInRoomBooking,
    ICreateOrUpdateBookingTmp,
    IRoomBookingList,
    IRoomBookingSchedule,
    IRoomBookingScheduleState,
    IRoomBookingStatisticItem,
    IRoomGroupBookingSchedule,
    IStatisticBookingItem,
} from '../interfaces';
import { roomBookingService } from '../services/room-booking.service';
import { StopRoomCause } from '~features/room-management/constants';

const initialState: IRoomBookingScheduleState = {
    stoppingInventoryStatus: [],
    stoppingRoomDates: {},
    stoppingRoomList: {},
    numberOfRoomBookingItems: {},
    bookingGroupById: {},
    defaultBookingOptions: {},
    bookingList: {},
    bookingStatisticList: [],
    bookingsCount: [],
    roomList: [],
    collapseRooms: [],
    roomTypesDropdown: [],
    selectedRoomBookingSchedule: null,
    isDraggingBooking: false,
    isFetchingBookingList: false,
    isShowCreateBookingForm: false,
    isShowCheckInForm: false,
    visibleCreateBookingPopover: false,
    currentDragData: null,
    currentViewMode: ScheduleViewMode.WEEK,
    currentDate: customDayjs().fmYYYYMMDD(),
    isSelecting: false,
    isBlockedRoomsSelecting: false,
    isFetchingRoomList: false,
    isCreatingBookingItem: false,
    isCreatingGroupBookingItem: false,
    startPosition: null,
    currentBookingEditing: null,
    panelId: '',
    numberOfRooms: 1,
    statisticsByDate: {},
    currentStatus: RoomBookingItemBookingStatus.ALL,
    startDateWeekView: customDayjs().subtract(1, 'week').startOf('week')?.fmYYYYMMDD('-'),
    endDateWeekView: customDayjs().add(1, 'week').endOf('week')?.fmYYYYMMDD('-'),
    startDateDayView: customDayjs().subtract(1, 'day').startOf('day')?.fmYYYYMMDD('-'),
    endDateDayView: customDayjs().add(1, 'day').endOf('day')?.fmYYYYMMDD('-'),
    bookingTemporaryList: [],
    isLoadingCheckIn: false,
    isShowUnassignList: false,
    dateHeaderFilterUnassignForm: null,
    roomViewId: 0,
};

export const fetchRoomBookingStatisticByDate = createAsyncThunk(
    'roomBooking/getRoomBookingStatisticByDate',
    async (_, { getState }) => {
        const time = monthSelector(getState() as AppState);
        return await roomBookingService.getRoomBookingStatisticByDate(
            time.start.fmYYYYMMDDHHmmss('-'),
            time.end.fmYYYYMMDDHHmmss('-'),
        );
    },
);

export const checkInRoomBooking = createAsyncThunk(
    'roomBooking/checkInRoomBooking',
    async (data: { id: number; checkinRoomBooking: ICheckInRoomBooking }) => {
        return await roomBookingService.checkInRoomBooking(
            data.id,
            data.checkinRoomBooking,
        );
    },
);

export const bulkUpdateTempBooking = createAsyncThunk(
    'roomBooking/bulkUpdateTempBooking',
    async (ids: number[]) => {
        return await roomBookingService.bulkUpdateTempBooking(ids);
    },
);

export const getRoomList = createAsyncThunk(
    'roomBookingSchedule/getRoomList',
    async (_, { getState }) => {
        const time = timeSelector(getState() as AppState);
        const { startDate, endDate } = getStartDateAndEndDate(
            time.start.fmYYYYMMDD(),
            time.end.fmYYYYMMDD(),
        );
        return await roomBookingService.getRoomList(startDate, endDate);
    },
);

export const getBookingList = createAsyncThunk(
    'roomBookingSchedule/getBookingList',
    async (date: { start: string; end: string }) => {
        return await roomBookingService.getBookingList(
            parseDate(date.start)?.fmYYYYMMDDHHmmss('-'),
            parseDate(date.end)?.fmYYYYMMDDHHmmss('-'),
        );
    },
);

export const fetchMoreBookingList = createAsyncThunk(
    'roomBookingSchedule/fetchMoreBookingList',
    async (date: { start: string; end: string }) => {
        return await roomBookingService.getBookingList(
            parseDate(date.start)?.fmYYYYMMDDHHmmss('-'),
            parseDate(date.end)?.fmYYYYMMDDHHmmss('-'),
        );
    },
);

export const getTemporaryBookingItemList = createAsyncThunk(
    'roomBookingSchedule/getTemporaryBookingItemList',
    async () => {
        return await roomBookingService.getTemporaryBookingItemList();
    },
);

export const createBookingItem = createAsyncThunk(
    'roomBookingSchedule/createBookingItem',
    async (
        {
            booking,
            isAcceptOverbooking,
        }: {
            booking: IRoomBookingSchedule;
            isAcceptOverbooking?: boolean;
        },
        { dispatch },
    ) => {
        let response: any = {};
        response = await roomBookingService.createTemporaryBooking(
            booking,
            isAcceptOverbooking,
        );
        dispatch(getStatisticByDate());
        return response as IBodyResponse<ICreateOrUpdateBookingTmp>;
    },
);

export const createGroupBookingItems = createAsyncThunk(
    'roomBookingSchedule/createGroupBookingItems',
    async (
        {
            bookings,
            isAcceptOverbooking,
        }: {
            bookings: IRoomGroupBookingSchedule[];
            isAcceptOverbooking?: boolean;
        },
        { dispatch },
    ) => {
        let response: any = {};
        response = await roomBookingService.createTemporaryGroupBooking(
            bookings,
            isAcceptOverbooking,
        );
        dispatch(getStatisticByDate());
        return response as IBodyResponse<ICreateOrUpdateBookingTmp>[];
    },
);

export const deleteBookingItem = createAsyncThunk(
    'roomBookingSchedule/deleteBookingItem',
    async (id: number, { dispatch }) => {
        let response: any = {};
        response = await roomBookingService.deleteTemporaryItem(id);
        dispatch(getStatisticByDate());
        return response as IBodyResponse<ICreateOrUpdateBookingTmp>;
    },
);

export const unassignBookingItemAndTmp = createAsyncThunk(
    'roomBookingSchedule/unassignBookingItemAndTmp',
    async (booking: IRoomBookingSchedule, { dispatch }) => {
        let response: any = {};
        response = await roomBookingService.unassignBooking([booking.id]);
        dispatch(getStatisticByDate());
        return response as IBodyResponse<any>;
    },
);

export const updateBookingItem = createAsyncThunk(
    'roomBookingSchedule/updateBookingItem',
    async (
        {
            booking,
            isUpdateReceipt,
        }: { booking: IRoomBookingSchedule; isUpdateReceipt?: boolean },
        { dispatch },
    ) => {
        let response: IBodyResponse<ICreateOrUpdateBookingTmp> | {} = {};
        if (booking.isTmp) {
            response = await roomBookingService.updateTemporaryBooking(booking);
        } else {
            response = await roomBookingService.updateRoomBookingItemWithBooking(
                booking,
                isUpdateReceipt,
            );
        }
        dispatch(getStatisticByDate());
        return response as IBodyResponse<ICreateOrUpdateBookingTmp>;
    },
);

export const getStopRoomInDuration = createAsyncThunk(
    'roomBookingSchedule/getStopRoomInDuration',
    async (_, { getState }) => {
        const state = getState() as AppState;
        const time = timeSelector(state);
        const { startDate, endDate } = getStartDateAndEndDateByDay(
            time.start.fmYYYYMMDD(),
            time.end.fmYYYYMMDD(),
        );
        return await inventoryService.getStopRoomInDuration({
            startDate,
            endDate,
        });
    },
);

export const getStopRoomInDurationWithStartAndEnd = createAsyncThunk(
    'roomBookingSchedule/getStopRoomInDuration',
    async ({ start, end }: { start: string; end: string }, { getState }) => {
        const { startDate, endDate } = getStartDateAndEndDateByDay(start, end);
        return await inventoryService.getStopRoomInDuration({
            startDate,
            endDate,
        });
    },
);

export const getStatisticByDate = createAsyncThunk(
    'roomBookingSchedule/getStatisticByDate',
    async (_, { getState }) => {
        const state = getState() as AppState;
        const time = timeSelector(state);
        return await roomBookingService.getStatisticByDate({
            startDate: time.start.startOf('day').fmYYYYMMDDHHmmss(),
            endDate: time.end.startOf('day').fmYYYYMMDDHHmmss(),
        });
    },
);

export const roomBookingScheduleSlice = createSlice({
    name: 'room-booking-schedule',
    initialState,
    reducers: {
        setIsDragging: (state, action) => {
            state.isDraggingBooking = action.payload;
        },
        setCurrentDragData: (state, action) => {
            state.currentDragData = action.payload;
        },
        setCurrentDate: (state, action) => {
            state.currentDate = action.payload;
        },
        setCurrentViewMode: (state, action) => {
            state.currentViewMode = action.payload;
            state.bookingList = {};
            state.currentDate = customDayjs().fmYYYYMMDD();
        },
        setSelecting: (state, action) => {
            state.isSelecting = action.payload;
        },
        setBlockedRoomsSelecting: (state, action) => {
            state.isBlockedRoomsSelecting = action.payload;
        },
        setStartPosition: (state, action) => {
            state.startPosition = action.payload;
        },
        setBookingList: (state, action) => {
            state.bookingList = action.payload;
        },
        setPanelId: (state, action) => {
            state.panelId = action.payload;
        },
        setCurrentStatus: (state, action) => {
            state.currentStatus = action.payload;
        },
        setShowCreateBookingForm: (state, action) => {
            state.isShowCreateBookingForm = action.payload;
        },
        setShowCheckInForm: (state, action) => {
            state.isShowCheckInForm = action.payload;
        },
        setVisibleCreateBookingPopover: (state, action) => {
            state.visibleCreateBookingPopover = action.payload;
        },
        setStartDateWeekView: (state, action) => {
            state.startDateWeekView = action.payload;
        },
        setEndDateDayView: (state, action) => {
            state.endDateDayView = action.payload;
        },
        setEndDateWeekView: (state, action) => {
            state.endDateWeekView = action.payload;
        },
        setStartDateDayView: (state, action) => {
            state.startDateDayView = action.payload;
        },
        setBookingTemporaryList: (state, action) => {
            state.bookingTemporaryList = action.payload;
        },
        setDateHeaderFilterUnassignForm: (state, action) => {
            state.dateHeaderFilterUnassignForm = action.payload;
        },
        updateBookingItemAndTmp: (state, action: PayloadAction<IAddBookingPayload>) => {
            const { bookings } = action.payload;
            forEach(bookings, (booking) => {
                state.bookingGroupById['booking_' + booking.id] = booking;
                if (booking.isTmp) {
                    const index = _.findIndex(
                        state.bookingTemporaryList,
                        (item) => item.id === booking.id,
                    );
                    if (booking.status === RoomBookingItemBookingStatus.CANCELLED) {
                        if (index > -1) {
                            state.bookingTemporaryList.splice(index, 1);
                        }
                        return;
                    }
                    if (index > -1) {
                        state.bookingTemporaryList[index] = booking;
                    } else {
                        state.bookingTemporaryList.push(booking);
                    }
                }
            });
            state.bookingList = parseBookingList(state.bookingGroupById);
        },
        removeBookingTmp: (state, action: PayloadAction<IAddBookingPayload>) => {
            const { bookings } = action.payload;
            forEach(bookings, (booking) => {
                delete state.bookingGroupById['booking_' + booking.id];
                if (booking.isTmp) {
                    const index = _.findIndex(
                        state.bookingTemporaryList,
                        (item) => item.id === booking.id,
                    );
                    if (index > -1) {
                        state.bookingTemporaryList.splice(index, 1);
                    } else {
                        state.bookingTemporaryList.push(booking);
                    }
                }
            });
            state.bookingList = parseBookingList(state.bookingGroupById);
        },
        bulkRemoveBookingTmp: (state, action: PayloadAction<{ ids: number[] }>) => {
            const { ids } = action.payload;
            ids.forEach((id) => {
                const index = _.findIndex(
                    state.bookingTemporaryList,
                    (item) => item.id === id,
                );
                if (index > -1) {
                    state.bookingTemporaryList.splice(index, 1);
                }
                delete state.bookingGroupById['booking_' + id];
            });
            state.bookingList = parseBookingList(state.bookingGroupById);
        },
        unassignBooking: (state, action: PayloadAction<IAddBookingPayload>) => {
            const { bookings } = action.payload;
            forEach(bookings, (booking) => {
                delete state.bookingGroupById['booking_' + booking.id];
            });
            state.bookingList = parseBookingList(state.bookingGroupById);
        },
        setCurrentBookingEditing: (state, action) => {
            state.currentBookingEditing = action.payload;
        },
        setCollapseRooms: (state, action) => {
            state.collapseRooms = action.payload;
        },
        setRoomBookingStatistic: (
            state,
            action: PayloadAction<IRoomBookingStatisticItem[]>,
        ) => {
            state.bookingStatisticList = action.payload;
        },
        setSelectedRoomBookingSchedule: (
            state,
            action: PayloadAction<IRoomBookingSchedule | null>,
        ) => {
            state.selectedRoomBookingSchedule = action.payload;
        },
        setDefaultBookingOptions: (state, action) => {
            state.defaultBookingOptions = action.payload;
        },
        setStoppingRoomList: (state, action) => {
            state.stoppingRoomList = action.payload;
        },
        setIsShowUnassignList: (state, action) => {
            state.isShowUnassignList = action.payload;
        },
        setRoomViewId: (state, action) => {
            state.roomViewId = action.payload;
        },
        resetWeekViewState: (state) => {
            state.startDateWeekView = customDayjs()
                .subtract(1, 'week')
                .startOf('week')
                ?.fmYYYYMMDD('-');
            state.endDateWeekView = customDayjs()
                .add(1, 'week')
                .endOf('week')
                ?.fmYYYYMMDD('-');
        },
        resetDayViewState: (state) => {
            state.startDateDayView = customDayjs()
                .subtract(1, 'day')
                .startOf('day')
                ?.fmYYYYMMDD('-');
            state.endDateDayView = customDayjs()
                .add(1, 'day')
                .endOf('day')
                ?.fmYYYYMMDD('-');
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRoomList.pending, (state, action) => {
            state.isFetchingRoomList = true;
        });
        builder.addCase(getRoomList.fulfilled, (state, action) => {
            if (action.payload.success) {
                const { roomList, roomTypesDropdown } = parseScheduleResponse(
                    action.payload.data,
                );
                state.roomList = roomList;
                state.roomTypesDropdown = roomTypesDropdown;
                state.numberOfRooms = action.payload.data?.numberOfRooms || 1;
            } else {
                state.roomList = [];
            }
            state.isFetchingRoomList = false;
        });
        builder.addCase(getBookingList.pending, (state, action) => {
            state.isFetchingBookingList = true;
            state.bookingList = {};
        });
        builder.addCase(getBookingList.fulfilled, (state, action) => {
            if (action.payload?.success) {
                const _bookingGroupById: Record<string, IRoomBookingSchedule> = {};
                _.forEach(action.payload.data, (booking) => {
                    _bookingGroupById['booking_' + booking.id] = convertBooking(booking);
                });
                state.bookingGroupById = _bookingGroupById;
                state.bookingList = parseBookingList(state.bookingGroupById);
            }
            state.isFetchingBookingList = false;
        });
        builder.addCase(fetchMoreBookingList.pending, (state, action) => {
            state.isFetchingBookingList = true;
        });
        builder.addCase(fetchMoreBookingList.fulfilled, (state, action) => {
            if (action.payload?.success) {
                if (action.payload?.data) {
                    _.forEach(action.payload.data, (booking) => {
                        state.bookingGroupById['booking_' + booking.id] =
                            convertBooking(booking);
                    });
                    state.bookingList = parseBookingList(state.bookingGroupById);
                }
            }
            state.isFetchingBookingList = false;
        });
        builder.addCase(createBookingItem.fulfilled, (state, action) => {
            state.isCreatingBookingItem = false;
        });
        builder.addCase(createBookingItem.pending, (state, action) => {
            state.isCreatingBookingItem = true;
        });
        builder.addCase(unassignBookingItemAndTmp.fulfilled, (state, action) => {
            state.isCreatingBookingItem = false;
        });
        builder.addCase(unassignBookingItemAndTmp.pending, (state, action) => {
            state.isCreatingBookingItem = true;
        });
        builder.addCase(fetchRoomBookingStatisticByDate.pending, (state, action) => {
            state.isFetchingRoomList = true;
        });
        builder.addCase(fetchRoomBookingStatisticByDate.fulfilled, (state, action) => {
            state.isFetchingRoomList = false;
            state.bookingStatisticList = action.payload?.data?.items || [];
        });
        builder.addCase(getTemporaryBookingItemList.pending, (state, action) => {
            state.isFetchingRoomList = true;
        });
        builder.addCase(getTemporaryBookingItemList.fulfilled, (state, action) => {
            state.isFetchingRoomList = false;
            const bookingTmpResponse = action.payload?.data || [];
            const _bookingTemporaryList: IRoomBookingSchedule[] =
                convertRoomBookingStaticItemToRoomBookingSchedule(bookingTmpResponse);
            state.bookingTemporaryList = _bookingTemporaryList;
        });
        builder.addCase(getStatisticByDate.fulfilled, (state, action) => {
            const list = action.payload?.data?.items || [];
            const _numberOfRoomBookingItems: Record<
                string,
                Record<string, IStatisticBookingItem>
            > = {};
            forEach(list, (item) => {
                const date = parseDate(item.date)?.fmYYYYMMDD();
                if (!_numberOfRoomBookingItems[date]) {
                    _numberOfRoomBookingItems[date] = {};
                }
                _numberOfRoomBookingItems[date]['roomType_' + item.roomType?.id] = item;
            });
            state.numberOfRoomBookingItems = _numberOfRoomBookingItems;
            state.statisticsByDate = buildStatisticsByDate(
                state.numberOfRoomBookingItems,
                state.stoppingInventoryStatus,
            );
        });
        builder.addCase(getStopRoomInDuration.fulfilled, (state, action) => {
            const list = action.payload?.data || [];
            const _stoppingRoomList = cloneDeep(state.stoppingRoomList);
            const _stoppingRoomDates = cloneDeep(state.stoppingRoomDates);
            forEach(list, (item) => {
                const date = parseDate(item.date)?.fmYYYYMMDD();
                if (!_stoppingRoomList[date]) {
                    _stoppingRoomList[date] = {};
                }
                forEach(item.stoppingInventoryItems, (room) => {
                    const key = `room_${room.roomId}`;
                    if (!_stoppingRoomDates[key]) {
                        _stoppingRoomDates[key] = {};
                    }
                    _stoppingRoomDates[key][date] = true;
                    const roomTypeKey = 'roomType_' + item.roomTypeId;
                    if (!_stoppingRoomList[date][roomTypeKey]) {
                        _stoppingRoomList[date][roomTypeKey] = {};
                    }
                    _stoppingRoomList[date][roomTypeKey][key] = {
                        stopping: true,
                        memo: room.memo,
                        reason: room.reason,
                    };
                });
            });

            const newStopingRoomList = list.reduce(
                (acc, i) => {
                    const date = parseDate(i.date)?.fmYYYYMMDD();
                    const obj = {
                        [`roomType_${i.roomTypeId}`]: i.stoppingInventoryItems.reduce(
                            (acc, sii) => {
                                acc[`room_${sii.roomId}`] = {
                                    stopping: true,
                                    memo: sii.memo,
                                    reason: sii.reason as StopRoomCause,
                                    roomTypeId: i.roomTypeId,
                                    roomId: sii.roomId,
                                };
                                return acc;
                            },
                            {} as Record<
                                string,
                                {
                                    stopping: boolean;
                                    roomTypeId: number;
                                    roomId: number;
                                    memo?: string;
                                    reason?: StopRoomCause;
                                }
                            >,
                        ),
                    };
                    acc[date] = { ...acc[date], ...obj };
                    return acc;
                },
                {} as Record<
                    string,
                    Record<
                        string,
                        Record<
                            string,
                            {
                                stopping: boolean;
                                roomTypeId: number;
                                roomId: number;
                                memo?: string;
                                reason?: StopRoomCause;
                            }
                        >
                    >
                >,
            );
            state.stoppingInventoryStatus = list;
            state.stoppingRoomDates = _stoppingRoomDates;
            state.stoppingRoomList = newStopingRoomList;
            state.statisticsByDate = buildStatisticsByDate(
                state.numberOfRoomBookingItems,
                state.stoppingInventoryStatus,
            );
        });
        builder.addCase(checkInRoomBooking.pending, (state, action) => {
            state.isLoadingCheckIn = true;
        });
        builder.addCase(checkInRoomBooking.fulfilled, (state, action) => {
            state.isLoadingCheckIn = false;
        });
        builder.addCase(createGroupBookingItems.fulfilled, (state, action) => {
            state.isCreatingGroupBookingItem = false;
        });
        builder.addCase(createGroupBookingItems.pending, (state, action) => {
            state.isCreatingGroupBookingItem = true;
        });
    },
});

export const {
    setIsDragging,
    setCurrentDragData,
    setCurrentDate,
    setCurrentViewMode,
    setSelecting,
    setBlockedRoomsSelecting,
    setStartPosition,
    setPanelId,
    resetWeekViewState,
    resetDayViewState,
    updateBookingItemAndTmp,
    setCurrentStatus,
    setCurrentBookingEditing,
    setCollapseRooms,
    setRoomBookingStatistic,
    setShowCreateBookingForm,
    setShowCheckInForm,
    setEndDateDayView,
    setEndDateWeekView,
    setStartDateWeekView,
    setStartDateDayView,
    removeBookingTmp,
    setBookingTemporaryList,
    setBookingList,
    unassignBooking,
    setVisibleCreateBookingPopover,
    setSelectedRoomBookingSchedule,
    bulkRemoveBookingTmp,
    setDefaultBookingOptions,
    setStoppingRoomList,
    setIsShowUnassignList,
    setDateHeaderFilterUnassignForm,
    setRoomViewId,
} = roomBookingScheduleSlice.actions;

export const scheduleStateSelector = (state: AppState) => {
    return state.roomBookingSchedule;
};
export const isSelectingSelector = (state: AppState) => {
    return state.roomBookingSchedule.isSelecting;
};
export const isDayViewSelector = (state: AppState) => {
    return state.roomBookingSchedule.currentViewMode === ScheduleViewMode.DAY;
};
export const currentViewModeSelector = (state: AppState) => {
    return state.roomBookingSchedule.currentViewMode;
};
export const bookingListSelector = (state: AppState) => {
    return state.roomBookingSchedule.bookingList;
};

export const dateSelector = (state: AppState) => {
    return {
        startDate: state.roomBookingSchedule.startDateWeekView,
        endDate: state.roomBookingSchedule.endDateWeekView,
    };
};

export const roomFilterSelector = (state: AppState) => {
    const { roomList, collapseRooms } = state.roomBookingSchedule;
    const results = roomList.filter((item) => {
        return (
            !item.parentId || (item.parentId && !collapseRooms.includes(item.parentId))
        );
    });
    return results;
};

export const bookingFilterSelector = (state: AppState) => {
    const { bookingList, currentStatus } = state.roomBookingSchedule;
    if (currentStatus === RoomBookingItemBookingStatus.ALL) {
        return bookingList;
    }
    const results: IRoomBookingList = {};
    _.forEach(bookingList, (roomBooking, date) => {
        results[date] = {};
        _.forEach(roomBooking, (booking, roomKey) => {
            const _bookings = booking.filter((item) => {
                return item.status === currentStatus;
            });
            results[date][roomKey] = _bookings;
        });
    });
    return results;
};

export const bookingTmpListSelector = (state: AppState) => {
    const { bookingTemporaryList } = state.roomBookingSchedule;
    // const bookingTmpList: IRoomBookingSchedule[] = [];
    return _.sortBy(bookingTemporaryList, ['stayingStartDate']);
};

export const timeSelector = (state: AppState) => {
    const {
        startDateWeekView,
        endDateWeekView,
        currentViewMode,
        startDateDayView,
        endDateDayView,
    } = state.roomBookingSchedule;
    if (currentViewMode === ScheduleViewMode.WEEK) {
        return {
            start: parseDate(startDateWeekView),
            end: parseDate(endDateWeekView),
        };
    }
    return {
        start: parseDate(startDateDayView).subtract(1, 'day').startOf('day'),
        end: parseDate(endDateDayView).add(1, 'day').endOf('day'),
    };
};

export const monthSelector = (state: AppState) => {
    const { currentDate } = state.roomBookingSchedule;
    const start = parseDate(currentDate).startOf('month');
    const end = parseDate(currentDate).endOf('month');
    return {
        start,
        end,
    };
};

export const weekColumnsSelector = (state: AppState) => {
    const { startDateWeekView, endDateWeekView } = state.roomBookingSchedule;
    return getDayColumns(startDateWeekView, endDateWeekView);
};

export const daysColumnsSelector = (state: AppState) => {
    const { startDateDayView, endDateDayView } = state.roomBookingSchedule;
    return getHoursColumns(startDateDayView, endDateDayView);
};

export const selectedRoomBookingScheduleSelector = (state: AppState) => {
    return state.roomBookingSchedule.selectedRoomBookingSchedule;
};

export const roomBookingPlanSelector = (state: AppState) => {
    const { bookingList } = state.roomBookingSchedule;
    const result: Record<string, IBookingPlanItem[]> = {};
    _.forEach(bookingList, (items) => {
        _.forEach(items, (bookings, roomKey) => {
            const roomTypeKey = 'roomType_' + roomKey.split('-')[0];
            if (!result[roomTypeKey]) {
                result[roomTypeKey] = [];
            }
            _.forEach(bookings, (booking) => {
                if (booking.roomId) {
                    result[roomTypeKey].push(getBookingPlan(booking));
                }
            });
        });
    });
    return result;
};

export default roomBookingScheduleSlice.reducer;
