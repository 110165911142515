import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep, differenceWith, find, forEach, max, sumBy } from 'lodash';
import { DEFAULT_FIRST_PAGE } from '~common/constants';
import { FacilityBookingStatus } from '~features/facility-booking/constants';
import { getBookingListFromBooking } from '~features/facility-booking/helper';
import { IndicatorType } from '~features/indicator/constants';
import { RoomBookingItemBookingStatus } from '~features/room-booking/constants';
import { IRoomBookingSchedule } from '~features/room-booking/interfaces';
import { roomBookingService } from '~features/room-booking/services/room-booking.service';
import { parseDate, todayDayjs } from '~plugins/dayjs';
import { AppState } from '~plugins/redux-toolkit/store';
import { RoomManagementStatus } from '../constants';
import { convertToRoomManagementItem } from '../helper';
import {
    IChangeSellingStatusRoom,
    IRoomStatus,
    IUpdateRoomCleaningStatus,
    RoomManagementState,
} from '../interfaces';
import { roomManagementService } from '../services/room-management.service';

const initialState: RoomManagementState = {
    isGettingDetail: false,
    currentIndicatorId: null,
    currentDate: todayDayjs.format('YYYY-MM-DD'),
    isShowBookingList: false,
    isUnassignLoading: false,
    isAssignLoading: false,
    isBlockRoomLoading: false,
    isUpdateRoomStatusLoading: false,
    bookingList: {},
    statisticsByDate: {},
    assignedList: {},
    currentStatus: RoomManagementStatus.ALL,
    indicatorDropdown: [],
    unassignedList: [],
    isGettingIndicatorDropdown: false,
    isDraggingBooking: false,
    isGettingUnassignedList: false,
    isChangeRoomCleanStatus: false,
    dragData: null,
    assignRoomLoading: null,
    room: null,
    facilityBookings: {},
    isGettingFacilityBookings: false,
    isGettingRoomManagementBookingOverview: false,
    roomManagementBookingOverview: null,
    availableRoomsByBooking: {},
    currentUnassignPage: DEFAULT_FIRST_PAGE,
    dateFilterUnassignBookingList: ['', ''],
    selectedRoomStopSellingCause: null,
};

export const getRoomManagementBookingOverview = createAsyncThunk(
    'roomManagement/getBookingOverview',
    async (data?: { start?: string; end?: string }) => {
        const { start, end } = data || {};
        const _start = start
            ? parseDate(start).startOf('day')
            : todayDayjs.startOf('day');
        const _end = end ? parseDate(end).endOf('day') : todayDayjs.endOf('day');
        const startOfDay = _start.fmYYYYMMDDHHmmss();
        const endOfDay = _end.fmYYYYMMDDHHmmss();
        const queryString = {
            datePeriod: [startOfDay, endOfDay],
        };
        return await roomManagementService.getRoomManagementBookingOverview(queryString);
    },
);

export const getRoomListStatus = createAsyncThunk(
    'roomManagement/getRoomListStatus',
    async (_, { getState }) => {
        const state = getState() as AppState;
        const detail = roomManagementDetailSelector(state);
        const ids = detail?.indicatorItems?.map((item) => item.roomId) || [];
        if (!ids.length) {
            return;
        }
        const { currentDate } = state.roomManagement;
        const response = await roomManagementService.getRoomListStatus(
            ids as number[],
            currentDate,
        );
        return response.data;
    },
);

export const getRoomStatus = createAsyncThunk(
    'roomManagement/getRoomStatus',
    async ({ id, currentDate }: { id: number; currentDate: string }) => {
        const response = await roomManagementService.getRoomListStatus(
            [id] as number[],
            parseDate(currentDate)?.fmYYYYMMDD(),
        );
        return response.data;
    },
);

export const getIndicatorDropdown = createAsyncThunk(
    'roomManagement/getIndicatorDropdown',
    async () => {
        return await roomManagementService.getIndicatorDropdown(IndicatorType.ROOM);
    },
);

export const assignBookingToRoom = createAsyncThunk(
    'roomManagement/assignBookingToRoom',
    async (payload: { id: number; roomId: number }[]) => {
        return await roomManagementService.assignBookingToRoom(payload);
    },
);

export const reassignBookingRoom = createAsyncThunk(
    'roomManagement/assignBookingToRoom',
    async (payload: { id: number; roomId: number }) => {
        return await roomBookingService.updateRoomBookingItemWithBooking(
            {
                id: payload.id,
                room: { id: payload.roomId },
            } as IRoomBookingSchedule,
            false,
            true,
        );
    },
);

export const unassignBooking = createAsyncThunk(
    'roomManagement/unassignBooking',
    async (payload: number[]) => {
        return await roomManagementService.unassignBooking(payload);
    },
);

export const changeSellingStatusRoom = createAsyncThunk(
    'roomManagement/stopSellingRoom',
    async ({ roomId, body }: { roomId: number; body: IChangeSellingStatusRoom }) => {
        return await roomManagementService.changeSellingStatusRoom(roomId, body);
    },
);

export const updateCleaningStatus = createAsyncThunk(
    'roomManagement/updateCleaningStatus',
    async (body: IUpdateRoomCleaningStatus) => {
        return await roomManagementService.updateCleaningStatus(body);
    },
);

export const getBookingUnassignedList = createAsyncThunk(
    'roomManagement/getBookingUnassignedList',
    async (
        options:
            | { start: string; end: string; isFromBookingSchedule?: boolean }
            | undefined,
        { getState },
    ) => {
        const state = getState() as AppState;
        const _start = options?.start || state.roomManagement.currentDate;
        const _end = options?.end || state.roomManagement.currentDate;
        const params = {
            startDate: parseDate(_start).startOf('day')?.fmYYYYMMDDHHmmss(),
            endDate: parseDate(_end).endOf('day')?.fmYYYYMMDDHHmmss(),
            isFromBookingSchedule: options?.isFromBookingSchedule,
        };
        return await roomManagementService.getBookingUnassignedList(params);
    },
);

export const getFacilityBookings = createAsyncThunk(
    'roomManagement/getFacilityBookings',
    async (_, { getState }) => {
        const state = getState() as AppState;
        const currentIndicator = roomManagementDetailSelector(state);
        const ids = (currentIndicator?.indicatorItems?.map((item) => item.facilityId) ||
            []) as number[];
        return await roomManagementService.getFacilityBookings(
            state.roomManagement.currentDate,
            ids,
        );
    },
);

const roomManagementSlice = createSlice({
    name: 'roomManagement',
    initialState,
    reducers: {
        setCurrentIndicatorId: (state, action) => {
            state.currentIndicatorId = action.payload;
            const item = state.indicatorDropdown.find(
                (_item) => _item.id === action.payload,
            );
            state.assignedList = convertToRoomManagementItem(item?.indicatorItems || []);
        },
        setCurrentDate: (state, action) => {
            state.currentDate = action.payload;
        },
        setCurrentStatus: (state, action) => {
            state.currentStatus = action.payload;
        },
        setIsShowBookingList: (state, action) => {
            state.isShowBookingList = action.payload;
        },
        setIsDraggingBooking: (state, action) => {
            state.isDraggingBooking = action.payload;
        },
        setDragData: (state, action) => {
            state.dragData = action.payload;
        },
        setAssignRoomLoading: (state, action: PayloadAction<number | null>) => {
            state.assignRoomLoading = action.payload;
        },
        setDateFilterUnassignBookingList: (state, action) => {
            state.dateFilterUnassignBookingList = action.payload;
        },
        setUnassignRoomList: (state, action) => {
            state.unassignedList = action.payload;
        },
        assignedRoomSuccess: (
            state,
            action: PayloadAction<{ roomId: number; bookingId: number }[]>,
        ) => {
            const list = cloneDeep(action.payload);
            const _assignedList = cloneDeep(state.assignedList);
            const _unassignedList = cloneDeep(state.unassignedList);
            forEach(_assignedList, (room) => {
                for (let i = 0; i < list.length; i++) {
                    const item = list[i];
                    const index = _unassignedList.findIndex(
                        (_booking) => _booking.id === item.bookingId,
                    );
                    if (index >= 0 && room.id === item.roomId) {
                        room.status = RoomManagementStatus.STAY;
                        room.booking =
                            index >= 0 ? cloneDeep(_unassignedList[index]) : undefined;
                        room.price = room.booking?.price || 0;
                        _unassignedList.splice(index, 1);
                        if (!list.length) {
                            return false;
                        }
                        break;
                    }
                }
            });
            state.assignedList = _assignedList;
            state.unassignedList = differenceWith(
                _unassignedList,
                list,
                (bookingItem, item) => bookingItem.id === item.bookingId,
            );
        },
        reassignedRoomSuccess: (
            state,
            action: PayloadAction<{ roomId: number; bookingId: number }>,
        ) => {
            const item = cloneDeep(action.payload);
            const _assignedList = cloneDeep(state.assignedList);
            forEach(_assignedList, (room) => {
                if (room.id === item.roomId) {
                    const prevRoom = find(
                        _assignedList,
                        (_prevRoom) => _prevRoom.booking?.id === item.bookingId,
                    );

                    if (prevRoom) {
                        const individualUnpaidAmount =
                            prevRoom.individualUnpaidAmount || 0;

                        // if the room is already assigned to another booking, it will be swapped
                        if (room.booking) {
                            const temp = room.booking;
                            room.booking = prevRoom?.booking;
                            prevRoom.booking = temp;
                            prevRoom.price = prevRoom.booking?.price || 0;
                            prevRoom.individualUnpaidAmount =
                                room.individualUnpaidAmount || 0;
                        } else {
                            room.status = RoomManagementStatus.STAY;
                            room.booking = prevRoom?.booking;
                            prevRoom.status = RoomManagementStatus.VACANCY;
                            prevRoom.booking = undefined;
                        }
                        room.price = room.booking?.price || 0;
                        room.individualUnpaidAmount = individualUnpaidAmount;
                    }
                }
            });

            state.assignedList = _assignedList;
        },
        unassignedBookingSuccess: (state, action: PayloadAction<number[]>) => {
            const list = action.payload;
            const _assignedList = cloneDeep(state.assignedList);
            forEach(_assignedList, (room) => {
                for (let i = 0; i < list.length; i++) {
                    const bookingId = list[i];
                    if (room.booking?.id === bookingId) {
                        room.status = RoomManagementStatus.VACANCY;
                        room.booking = undefined;
                        list.splice(i, 1);
                        if (!list.length) {
                            return false;
                        }
                        break;
                    }
                }
            });
            state.assignedList = _assignedList;
        },
        removeBookingFromUnassignList: (
            state,
            action: PayloadAction<{ ids: number[] }>,
        ) => {
            const { ids } = action.payload;
            forEach(ids, (id) => {
                const index = state.unassignedList.findIndex((item) => item.id === id);
                if (index >= 0) {
                    state.unassignedList.splice(index, 1);
                }
            });
        },
        removeBookingFromAssignList: (
            state,
            action: PayloadAction<{ ids: number[] }>,
        ) => {
            const { ids } = action.payload;
            const _assignedList = cloneDeep(state.assignedList);
            forEach(_assignedList, (room) => {
                if (room.booking && ids.includes(room.booking.id)) {
                    room.status = RoomManagementStatus.VACANCY;
                    room.booking = undefined;
                }
            });
            state.assignedList = _assignedList;
        },
        bookingCheckInSuccess: (state, action: PayloadAction<number>) => {
            const bookingId = action.payload;
            const _assignedList = cloneDeep(state.assignedList);
            forEach(_assignedList, (room) => {
                if (room.booking?.id === bookingId) {
                    room.booking.bookingStatus = RoomBookingItemBookingStatus.CHECKED_IN;
                }
            });
            state.assignedList = _assignedList;
        },
        changeBookingStatusSuccess: (
            state,
            action: PayloadAction<{
                bookingId: number;
                status: RoomBookingItemBookingStatus;
            }>,
        ) => {
            const { bookingId, status } = action.payload;
            const _assignedList = cloneDeep(state.assignedList);
            forEach(_assignedList, (room) => {
                if (room.booking?.id === bookingId) {
                    room.booking.bookingStatus = status;
                }
            });
            state.assignedList = _assignedList;
        },
        setAvailableRoomsByBooking: (state, action) => {
            state.availableRoomsByBooking = action.payload;
        },
        setCurrentUnassignPage(state, action: PayloadAction<number>) {
            state.currentUnassignPage = action.payload;
        },
        setSelectedRoomStopSellingCause: (state, action) => {
            state.selectedRoomStopSellingCause = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(changeSellingStatusRoom.pending, (state, action) => {
            state.isBlockRoomLoading = true;
        });
        builder.addCase(changeSellingStatusRoom.fulfilled, (state, action) => {
            state.isBlockRoomLoading = false;
        });
        builder.addCase(getRoomStatus.pending, (state, action) => {
            state.room = null;
            state.isUpdateRoomStatusLoading = true;
        });
        builder.addCase(getRoomStatus.fulfilled, (state, action) => {
            state.room = action?.payload?.items?.[0] || null;
            state.isUpdateRoomStatusLoading = false;
        });
        builder.addCase(updateCleaningStatus.pending, (state, action) => {
            state.isChangeRoomCleanStatus = true;
        });
        builder.addCase(updateCleaningStatus.fulfilled, (state, action) => {
            state.isChangeRoomCleanStatus = false;
        });
        builder.addCase(assignBookingToRoom.pending, (state, action) => {
            state.isAssignLoading = true;
        });
        builder.addCase(assignBookingToRoom.fulfilled, (state, action) => {
            state.isAssignLoading = false;
            state.assignRoomLoading = null;
        });
        builder.addCase(getRoomListStatus.pending, (state, action) => {
            state.isGettingDetail = true;
        });
        builder.addCase(getRoomListStatus.fulfilled, (state, action) => {
            state.isGettingDetail = false;
            const list: Record<string, IRoomStatus> = {};
            action.payload?.items?.forEach((item) => {
                list['id_' + item.id] = item;
            });
            const _assignedList = cloneDeep(state.assignedList);
            forEach(_assignedList, (room) => {
                room.status =
                    list['id_' + room.id]?.status || RoomManagementStatus.VACANCY;
                room.memo = list['id_' + room.id]?.memo || '';
                room.reason = list['id_' + room.id]?.reason || '';
                room.price = list['id_' + room.id]?.totalAmountRoomBooking || 0;
                room.booking = list['id_' + room.id]?.roomBookingItem || undefined;
                room.cleaningStatus = list['id_' + room.id]?.cleaningStatus || undefined;
                room.startDate = list['id_' + room.id]?.startDate || undefined;
                room.endDate = list['id_' + room.id]?.endDate || undefined;
                room.individualUnpaidAmount =
                    list['id_' + room.id]?.individualUnpaidAmount || 0;
            });
            state.assignedList = _assignedList;
        });
        builder.addCase(getIndicatorDropdown.pending, (state, action) => {
            state.isGettingIndicatorDropdown = true;
        });
        builder.addCase(getIndicatorDropdown.fulfilled, (state, action) => {
            state.isGettingIndicatorDropdown = false;
            state.indicatorDropdown = action.payload?.data?.items || [];
            const firstItem = state.indicatorDropdown?.[0];
            if (firstItem?.id) {
                state.currentIndicatorId = firstItem?.id;
                state.assignedList = convertToRoomManagementItem(
                    firstItem?.indicatorItems || [],
                );
            }
        });
        builder.addCase(getBookingUnassignedList.pending, (state, action) => {
            state.isGettingUnassignedList = true;
            state.currentUnassignPage = 1;
        });
        builder.addCase(getBookingUnassignedList.fulfilled, (state, action) => {
            state.isGettingUnassignedList = false;
            state.unassignedList = (action.payload?.data?.items || []).map((item) => ({
                ...item,
                price: item.totalAmount || 0,
            }));
        });
        builder.addCase(getFacilityBookings.pending, (state, action) => {
            state.isGettingFacilityBookings = true;
            state.facilityBookings = {};
        });
        builder.addCase(getFacilityBookings.fulfilled, (state, action) => {
            const _facilityBookings = cloneDeep(state.facilityBookings);
            const data = (action.payload?.data || []).filter(
                (item) => item.status !== FacilityBookingStatus.CANCELLED,
            );
            forEach(data, (booking) => {
                const _bookingList = getBookingListFromBooking(booking);
                forEach(_bookingList, (items) => {
                    forEach(items, (_bookings, facilityKey) => {
                        if (!_facilityBookings[facilityKey])
                            _facilityBookings[facilityKey] = [];
                        _facilityBookings[facilityKey] = [
                            ..._facilityBookings[facilityKey],
                            ..._bookings,
                        ];
                    });
                });
            });
            const _assignedList = cloneDeep(state.assignedList);
            forEach(_assignedList, (room) => {
                room.status =
                    _facilityBookings['facility-' + room.id]?.length > 0
                        ? RoomManagementStatus.STAY
                        : RoomManagementStatus.VACANCY;
                const totalPrice =
                    sumBy(
                        _facilityBookings['facility-' + room.id] || [],
                        'totalAmount',
                    ) || 0;
                room.price = max([totalPrice, 0]);
            });
            state.assignedList = _assignedList;
            state.facilityBookings = _facilityBookings;
            state.isGettingFacilityBookings = false;
        });
        builder.addCase(getRoomManagementBookingOverview.pending, (state, action) => {
            state.isGettingRoomManagementBookingOverview = true;
        });
        builder.addCase(getRoomManagementBookingOverview.fulfilled, (state, action) => {
            state.isGettingRoomManagementBookingOverview = false;
            state.roomManagementBookingOverview = action.payload?.data || null;
        });
    },
});

export const {
    setCurrentIndicatorId,
    setCurrentDate,
    setCurrentStatus,
    setIsShowBookingList,
    setIsDraggingBooking,
    setDragData,
    assignedRoomSuccess,
    reassignedRoomSuccess,
    setUnassignRoomList,
    setDateFilterUnassignBookingList,
    setAssignRoomLoading,
    unassignedBookingSuccess,
    removeBookingFromUnassignList,
    bookingCheckInSuccess,
    removeBookingFromAssignList,
    changeBookingStatusSuccess,
    setAvailableRoomsByBooking,
    setCurrentUnassignPage,
    setSelectedRoomStopSellingCause,
} = roomManagementSlice.actions;

export const roomManagementBookingOverviewSelector = (state: AppState) =>
    state.roomManagement.roomManagementBookingOverview;

export const roomManagementSelector = (state: AppState) => state.roomManagement;

export const roomManagementDetailSelector = (state: AppState) => {
    const { indicatorDropdown, currentIndicatorId } = state.roomManagement;
    return indicatorDropdown.find((item) => item.id === currentIndicatorId);
};

export const validRoomsSelector = (state: AppState) => {
    const { dragData, unassignedList } = state.roomManagement;
    if (!dragData) return [];
    const { bookingId } = dragData;
    const booking = unassignedList?.find((item) => item.id === bookingId);
    if (!booking) return [];
    const roomType = booking.roomTypeId;
    return [roomType];
};

export default roomManagementSlice.reducer;
