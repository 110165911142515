import { notification } from 'antd';
import { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { RoomBookingItemBookingStatus } from '~features/room-booking/constants';
import {
    reassignBookingRoom,
    reassignedRoomSuccess,
    roomManagementSelector,
    setAssignRoomLoading,
    setDragData,
    setIsDraggingBooking,
} from '~features/room-management/reducers/room-management.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';

interface DragBoxProps {
    children: React.ReactNode;
    bookingId: number;
    currentRoomId: number;
    currentRoomTypeId: number;
    bookingStatus?: RoomBookingItemBookingStatus;
    disabled?: boolean;
}

export const DragBox = ({
    children,
    bookingId,
    currentRoomId,
    currentRoomTypeId,
    bookingStatus,
    disabled,
}: DragBoxProps) => {
    const dispatch = useAppDispatch();
    const { dragData } = useAppSelector(roomManagementSelector);

    const [{ isDragging }, drag] = useDrag(
        {
            type: 'box',
            item: { bookingId },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            canDrag: !disabled,
            isDragging: (monitor) => {
                return (
                    dragData?.bookingId === bookingId ||
                    monitor.getItem().bookingId === bookingId
                );
            },
            end: async (_, monitor) => {
                const dropResult = monitor.getDropResult() || {};
                const { roomId } = dropResult as {
                    roomId: number;
                    x: number;
                    y: number;
                };
                if (!roomId || roomId === currentRoomId) return;

                dispatch(setAssignRoomLoading(roomId));
                const response = await dispatch(
                    reassignBookingRoom({ id: bookingId, roomId }),
                );
                if (reassignBookingRoom.fulfilled.match(response)) {
                    if (response.payload?.success) {
                        dispatch(reassignedRoomSuccess({ bookingId, roomId }));
                    } else {
                        notification.error({
                            message: response.payload?.message || '',
                        });
                    }
                }

                dispatch(setAssignRoomLoading(null));
            },
        },
        [bookingId],
    );

    useEffect(() => {
        dispatch(setIsDraggingBooking(isDragging));
        dispatch(
            setDragData(
                isDragging
                    ? {
                          bookingId,
                          isAssigned: true,
                          roomTypeId: currentRoomTypeId,
                          bookingStatus,
                      }
                    : null,
            ),
        );
    }, [isDragging]);

    return (
        <div
            ref={drag}
            style={{
                width: '100%',
                height: '100%',
                cursor: disabled ? 'default' : 'pointer',
            }}
        >
            {children}
        </div>
    );
};
