import { Spin } from 'antd';
import {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    WheelEvent,
} from 'react';
import './ScheduleWeekView.scss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    scheduleStateSelector,
    setCollapseRooms,
    fetchMoreBookingList,
    getBookingList,
    setCurrentDate,
    getStopRoomInDuration,
    getStatisticByDate,
    setStartDateWeekView,
    setEndDateWeekView,
    resetWeekViewState,
} from '~features/room-booking/reducers/schedule.reducer';
import { showLoadingSelector } from '~features/inventory/reducers/inventory.reducer';
import customDayjs, { parseDate } from '~plugins/dayjs';
import { updateStyleForCellSelected } from '~features/room-booking/util';
import { AutoSizer, GridView, ICell } from '~components';
import { CellItem } from './CellItem';
import { GridOnItemsRenderedProps, VariableSizeGrid } from 'react-window';
import {
    CELL_HEADER_HEIGHT,
    CELL_HEIGHT,
    HEADER_HEIGHT,
    CELL_WIDTH,
    RoomBookingEvent,
} from '~features/room-booking/constants';
import { Header } from './Header';
import { RoomHeader } from './RoomHeader';
import { useHandleScroll, usePrevious, useThrottle } from '~common/useHooks';
import { useMitt } from '~plugins/mitt';
import { BookingList } from './BookingList';
import { DropBoxList } from './DropBoxList';
import { IDirection, IScrollStatus } from '~common/interfaces';
import { addMoreColumnsForWeek, getDayColumnsForWeek } from '~common/commonFunctions';
import { CollapseBtn } from '~features/room-booking/components/CollapseBtn/CollapseBtn';
import { EmitterGlobalEvent } from '~common/constants';
import { MAX_HEIGHT_SCHEDULE_VIEW } from '~features/facility-booking/constants';
import {
    calculateBookingScrollData,
    maxHeightRoomList,
} from '~features/room-booking/helper';
import { max, min } from 'lodash';
import { StopRoomCause } from '~features/room-management/constants';

export const ScheduleWeekView = (props: { height?: number }) => {
    const today = useMemo(() => {
        return customDayjs().fmYYYYMMDD('-');
    }, []);
    const {
        isSelecting,
        isBlockedRoomsSelecting,
        startPosition,
        panelId,
        isFetchingRoomList,
        collapseRooms,
        isDraggingBooking,
        isFetchingBookingList,
        currentDate,
        stoppingRoomList,
        roomList,
    } = useAppSelector(scheduleStateSelector);
    const isLoadingUpdateInventory = useAppSelector(showLoadingSelector);

    const { height } = props;
    const dispatch = useAppDispatch();
    const [columns, setColumns] = useState(getDayColumnsForWeek(currentDate));
    const outerRef = useRef<HTMLElement>();
    const gridRef = useRef<VariableSizeGrid>() as MutableRefObject<VariableSizeGrid>;
    const gridViewContentHeightRef = useRef<number>();
    const scrollPosition = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
    const { throttle } = useThrottle();
    const containerRef = useRef<HTMLDivElement>(null);
    const { emitter } = useMitt();
    const isLoadMore = useRef(false);
    const firstRender = useRef(true);

    const roomListFilter = useMemo(() => {
        return roomList.filter((item) => {
            return (
                !item.parentId ||
                (item.parentId && !collapseRooms.includes(item.parentId))
            );
        });
    }, [collapseRooms, roomList]);

    // handle scroll
    const onStopScroll = useCallback(
        (direction: IDirection, status: IScrollStatus) => {
            if (isFetchingBookingList) {
                return;
            }
            if (status === 'start' && direction === 'right') {
                throttle(() => {
                    loadMorePast();
                }, 1000);
            } else if (status === 'end' && direction === 'left') {
                throttle(() => {
                    loadMoreFuture();
                }, 1000);
            }
        },
        [isFetchingBookingList, columns],
    );

    const {
        scrollDirectionX,
        onMouseDown,
        onMouseUp,
        onMouseMove,
        onMouseLeave,
        elementRef,
        isScrollXAble,
        onWheel,
        scrollStatus,
    } = useHandleScroll({ onStopScroll });

    useEffect(() => {
        isScrollXAble.current = !isDraggingBooking;
    }, [isDraggingBooking, isScrollXAble]);

    // handle select day for create booking
    useEffect(() => {
        const _updateStyleForCellSelected = (x: number) => {
            if (!startPosition) return;
            updateStyleForCellSelected(
                panelId,
                startPosition,
                x,
                isBlockedRoomsSelecting,
            );
        };

        const onMouseMove = (event: MouseEvent) => {
            const element = event.target as HTMLElement;
            const x = element.dataset.x;
            const y = element.dataset.y;
            const roomId = element.dataset.room_id;
            const typeId = element.dataset.room_type_id;
            const date = element.dataset.day;

            // if cell selecting is not started yet, do nothing
            if (!isSelecting && !isBlockedRoomsSelecting) {
                return;
            }

            // if there is no x and y defined in the cell, do nothing
            if (!(x && y)) return;

            // if cell is more than 2 days before from today, do nothing
            if (customDayjs(today).subtract(2, 'day').isAfter(customDayjs(date))) return;

            if (roomId && typeId && date) {
                if (
                    // if selecting cells for blocking rooms or creating a booking, skip sellecting cells currently being blocked
                    // since in this operation, only non-blocked cells should be selected/colored to be blocked
                    (isSelecting &&
                        stoppingRoomList[date]?.['roomType_' + typeId]?.['room_' + roomId]
                            ?.stopping) ||
                    // if selecting cells for releasing rooms, skip sellecting non-blocked cells
                    // since in this operation, only blocked cells should be selected/colored to be released
                    (isBlockedRoomsSelecting &&
                        !stoppingRoomList[date]?.['roomType_' + typeId]?.[
                            'room_' + roomId
                        ]?.stopping)
                ) {
                    return;
                }
            }

            // otherwise, select/color the selected/mouse hover cell
            return _updateStyleForCellSelected(Number(x));
        };

        document.addEventListener('mousemove', onMouseMove);
        return () => {
            document.removeEventListener('mousemove', onMouseMove);
        };
    }, [
        isSelecting,
        isBlockedRoomsSelecting,
        dispatch,
        startPosition,
        panelId,
        stoppingRoomList,
    ]);

    // end handle select day for create booking

    const renderHeader = useCallback(
        ({ columnIndex, rowIndex, style }: ICell) => {
            const column = columns[columnIndex];
            const row = roomListFilter[rowIndex];
            return <Header column={column} row={row} style={style} today={today} />;
        },
        [columns, roomListFilter, today],
    );
    const renderCell = useCallback(
        ({ columnIndex, rowIndex, style }: ICell) => {
            const row = roomListFilter[rowIndex];
            const _date = columns[columnIndex].id;
            const disabled =
                stoppingRoomList[_date]?.['roomType_' + row.parentId]?.['room_' + row.id]
                    ?.stopping;
            const memo =
                stoppingRoomList[_date]?.['roomType_' + row.parentId]?.['room_' + row.id]
                    ?.memo;
            const reason = stoppingRoomList[_date]?.['roomType_' + row.parentId]?.[
                'room_' + row.id
            ]?.reason as
                | StopRoomCause.INVENTORY_ADJUSTMENT
                | StopRoomCause.ROOM_ISSUES
                | undefined;

            return (
                <CellItem
                    isToday={_date === today}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    item={row}
                    day={_date}
                    style={style}
                    disabled={!!disabled}
                    memo={memo}
                    reason={reason}
                ></CellItem>
            );
        },
        [roomListFilter, columns, today, stoppingRoomList],
    );

    const renderRowHeader = useCallback(
        ({ rowIndex, style }: ICell) => {
            const row = roomListFilter[rowIndex];
            return <RoomHeader item={row} style={style} />;
        },
        [collapseRooms, roomListFilter],
    );

    const getRowContentHeight = useCallback(
        (rowIndex: number) => {
            if (roomListFilter[rowIndex].parentId) {
                return CELL_HEIGHT;
            }
            return CELL_HEADER_HEIGHT;
        },
        [roomListFilter],
    );
    const isExpand = useMemo(() => {
        const ids = roomListFilter
            .filter((item) => !item.parentId)
            .map((item) => item.id);
        return collapseRooms.length === ids.length;
    }, [collapseRooms, roomListFilter]);

    const togglePanelAll = useCallback(() => {
        const ids = roomListFilter
            .filter((item) => !item.parentId)
            .map((item) => item.id);
        if (collapseRooms.length === ids.length) {
            dispatch(setCollapseRooms([]));
        } else {
            dispatch(setCollapseRooms(ids));
        }
    }, [collapseRooms, roomListFilter]);

    useEffect(() => {
        emitter.on(RoomBookingEvent.CHANGE_DATE, (date: string) => {
            const _columns = getDayColumnsForWeek(date);
            setColumns(_columns);
            fetchBookingList(_columns[0].id, _columns[_columns.length - 1].id);
            updateNumberOfBooking(_columns[0].id, _columns[_columns.length - 1].id);
        });
        return () => {
            emitter.off(RoomBookingEvent.CHANGE_DATE);
        };
    }, [columns]);

    const updateNumberOfBooking = (start: string, end: string) => {
        dispatch(setStartDateWeekView(start));
        dispatch(setEndDateWeekView(end));
        dispatch(getStopRoomInDuration());
        dispatch(getStatisticByDate());
    };

    useEffect(() => {
        fetchBookingList(columns[0].id, columns[columns.length - 1].id);
        updateNumberOfBooking(columns[0].id, columns[columns.length - 1].id);
        return () => {
            dispatch(resetWeekViewState());
        };
    }, []);

    const isFetchingBookingListOld = usePrevious<boolean>(isFetchingBookingList);
    useEffect(() => {
        if (isFetchingBookingListOld && !isFetchingBookingList && !isLoadMore.current) {
            const currentDay = parseDate(currentDate);
            const todayIndex = columns.findIndex(
                (item) => item.id === currentDay.fmYYYYMMDD('-'),
            );
            if (todayIndex > -1) {
                gridRef.current?.scrollToItem({
                    // offset first render for a bit, location not rendering well
                    columnIndex: firstRender.current ? todayIndex + 1 : todayIndex - 1,
                    align: 'start',
                });
                firstRender.current = false;
            }
        }
    }, [isFetchingBookingList]);

    // handle load more item
    const fetchBookingList = async (start: string, end: string) => {
        isLoadMore.current = false;
        await dispatch(
            getBookingList({
                start,
                end,
            }),
        );
    };
    const loadMoreFuture = async () => {
        isLoadMore.current = true;
        const lastDay = columns[columns.length - 1].id;
        const moreColumns = addMoreColumnsForWeek(
            parseDate(lastDay).add(1, 'day'),
            false,
            14,
        );
        const columnsUpdated = [...columns, ...moreColumns];
        setColumns(columnsUpdated);
        updateNumberOfBooking(
            columnsUpdated[0].id,
            columnsUpdated[columnsUpdated.length - 1].id,
        );
        await dispatch(
            fetchMoreBookingList({
                start: moreColumns[0].id,
                end: moreColumns[moreColumns.length - 1].id,
            }),
        );
        gridRef.current?.scrollToItem({ columnIndex: columns.length + 1 });
    };
    const loadMorePast = async () => {
        isLoadMore.current = true;
        const firstDay = columns[0].id;
        const moreColumns = addMoreColumnsForWeek(parseDate(firstDay), true);
        const columnsUpdated = [...moreColumns, ...columns];
        setColumns(columnsUpdated);
        updateNumberOfBooking(
            columnsUpdated[0].id,
            columnsUpdated[columnsUpdated.length - 1].id,
        );
        await dispatch(
            fetchMoreBookingList({
                start: moreColumns[0].id,
                end: moreColumns[moreColumns.length - 1].id,
            }),
        );
        gridRef.current?.scrollToItem({ columnIndex: 5, align: 'start' });
    };

    const _onScroll = useCallback(
        ({ scrollLeft, scrollTop }: any) => {
            const scrollWidth = outerRef.current?.scrollWidth || 0;
            const clientWidth = containerRef.current?.clientWidth || 0;
            const scrollData = { x: scrollLeft, y: scrollTop };
            emitter.emit(EmitterGlobalEvent.SCROLL, scrollData);
            scrollPosition.current = scrollData;
            if (!scrollLeft) {
                scrollStatus.current = 'start';
            } else if (scrollWidth === scrollLeft + clientWidth - CELL_WIDTH) {
                scrollStatus.current = 'end';
            } else {
                scrollStatus.current = 'middle';
            }
        },
        [outerRef, gridRef, containerRef, columns],
    );

    const onItemsRendered = useCallback(
        (options: GridOnItemsRenderedProps) => {
            emitter.emit(EmitterGlobalEvent.SCHEDULE_SCROLL, options);
            const { visibleColumnStartIndex } = options;
            if (!elementRef.current) {
                elementRef.current = outerRef.current;
            }
            const column = columns[visibleColumnStartIndex];
            if (scrollDirectionX.current && column?.id) {
                dispatch(setCurrentDate(column.id));
            }
        },
        [columns, currentDate],
    );

    const onWheelBooking = (e: WheelEvent<HTMLDivElement>) => {
        const { deltaX, deltaY, shiftKey } = e;
        const { x, y } = scrollPosition.current;
        const { scrollLeft, scrollTop } = calculateBookingScrollData(
            { x, y },
            { deltaX, deltaY, shiftKey },
        );
        const maxHeight = maxHeightRoomList(roomListFilter, collapseRooms);
        const maxScrollTop = max([
            maxHeight - (height || MAX_HEIGHT_SCHEDULE_VIEW) + HEADER_HEIGHT,
            0,
        ]);
        gridRef.current.scrollTo({
            scrollLeft,
            scrollTop: max([min([scrollTop, maxScrollTop]), 0]),
        });
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div
                className="room-booking-schedule-table-wrapper"
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                onMouseLeave={onMouseLeave}
                onWheel={onWheel}
                ref={containerRef}
            >
                <Spin
                    spinning={
                        isFetchingRoomList ||
                        isFetchingBookingList ||
                        isLoadingUpdateInventory
                    }
                    style={{ minHeight: 400 }}
                >
                    <AutoSizer disableHeight>
                        {({ width }) => {
                            let cellWidth = (width - CELL_WIDTH) / 7;
                            if (cellWidth < CELL_WIDTH) {
                                cellWidth = CELL_WIDTH;
                            }
                            return (
                                <>
                                    <GridView
                                        style={{
                                            fontSize: 12,
                                        }}
                                        outerRef={outerRef}
                                        gridViewContentHeightRef={
                                            gridViewContentHeightRef
                                        }
                                        gridRef={gridRef}
                                        className={'schedule-table'}
                                        height={height}
                                        width={width}
                                        columns={columns}
                                        recordset={roomListFilter}
                                        rowHeaderWidth={CELL_WIDTH}
                                        columnHeaderWidth={cellWidth}
                                        columnHeaderHeight={HEADER_HEIGHT}
                                        columnHeaderRenderer={renderHeader}
                                        rowHeaderRenderer={renderRowHeader}
                                        cellRenderer={renderCell}
                                        getRowContentHeight={getRowContentHeight}
                                        onScroll={_onScroll}
                                        bodyProps={{
                                            onItemsRendered,
                                        }}
                                    />
                                    <BookingList
                                        cellWidth={cellWidth}
                                        onMouseUp={onMouseUp}
                                        startTime={parseDate(columns[0].id)}
                                        endTime={parseDate(
                                            columns[columns.length - 1].id,
                                        )}
                                        onWheel={onWheelBooking}
                                        gridRef={gridRef}
                                        outerRef={outerRef}
                                        gridViewContentHeightRef={
                                            gridViewContentHeightRef
                                        }
                                    />
                                    <DropBoxList
                                        startTime={parseDate(columns[0].id)}
                                        endTime={parseDate(
                                            columns[columns.length - 1].id,
                                        )}
                                        cellWidth={cellWidth}
                                    />
                                </>
                            );
                        }}
                    </AutoSizer>
                    <div
                        className="header-action"
                        style={{ width: CELL_WIDTH, height: HEADER_HEIGHT }}
                    >
                        <CollapseBtn isExpand={isExpand} onClick={togglePanelAll} />
                    </div>
                </Spin>
            </div>
        </DndProvider>
    );
};
