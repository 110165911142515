import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    MinusOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import { Button, Form, notification, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DateFormat,
    ErrorMessageType,
    MINUTES_PER_DAY,
    UserGroup,
} from '~common/constants';
import {
    InputText,
    ModalConfirmDeletion,
    RangePicker,
    SingleSelect,
    TextTruncate,
} from '~components';
import {
    IChildBooking,
    ICreateOrUpdateBookingTmp,
    IGetBookingPrice,
    IRoomBookingSchedule,
} from '~features/room-booking/interfaces';
import {
    createBookingItem,
    deleteBookingItem,
    getStatisticByDate,
    getTemporaryBookingItemList,
    removeBookingTmp,
    scheduleStateSelector,
    updateBookingItem,
    updateBookingItemAndTmp,
} from '~features/room-booking/reducers/schedule.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { useForm } from '~plugins/hook-form';
import './BookingTemporaryListMode.scss';

import classNames from 'classnames';
import { forEach, sumBy } from 'lodash';
import { RoomBooking } from 'src/types/v2/room-booking/room-booking.type';
import { checkUserPermission, formatMoney } from '~common/commonFunctions';
import { IBodyResponseTsRest } from '~common/interfaces';
import { showConfirm } from '~common/notification';
import { useSelectedHotel } from '~common/useHooks';
import { childrenStateSelector } from '~features/children-type/reducers/children.reducer';
import { PlanType } from '~features/plan/constants';
import { planDropdownSelector } from '~features/plan/reducers/plan.reducer';
import { ChildrenInput } from '~features/room-booking/components/ChildrenInput/ChildrenInput';
import InputAdult from '~features/room-booking/components/InputAdult/InputAdult';
import {
    CreateFacilityBookingField,
    InputAdultFieldName,
} from '~features/room-booking/constants';
import {
    getChildrenObject,
    validateGenderBreakdownOfGuest,
    validateStartDateInAfterTwoDaysBefore,
} from '~features/room-booking/helper';
import { ICreateGroupTempRoomBooking } from '~features/room-booking/interfaces/temp-room-booking/create-temp-room-booking.interface';
import { IUpdateSingleTempRoomBooking } from '~features/room-booking/interfaces/temp-room-booking/update-temp-room-booking.interface';
import { getBookingScheduleDefault } from '~features/room-booking/model';
import { OverBookingException } from '~features/room-booking/pages/GroupBookingPage/errors/OverBooking.exception';
import { TempRoomBookingHelper } from '~features/room-booking/pages/GroupBookingPage/helpers/TempRoomBooking/temp-room-booking.helper';
import useCreateGroupTempRoomBooking from '~features/room-booking/pages/GroupBookingPage/hooks/TempRoomBooking/useCreateGroupTempRoomBooking';
import useDeleteSingleTempRoomBooking from '~features/room-booking/pages/GroupBookingPage/hooks/TempRoomBooking/useDeleteSingleTempRoomBooking';
import useUpdateSingleTempRoomBooking from '~features/room-booking/pages/GroupBookingPage/hooks/TempRoomBooking/useUpdateSingleTempRoomBooking';
import { getBookingPrice } from '~features/room-booking/reducers/create-booking.reducer';
import { temporaryBookingItemSchemaResolver } from '~features/room-booking/schema';
import { Dayjs, parseDate, todayDayjs } from '~plugins/dayjs';
import { MutationKeys } from '~queries/queries';
import useDeleteGroupTempRoomBookings from '~features/room-booking/pages/GroupBookingPage/hooks/TempRoomBooking/useDeleteGroupTempBookings';

type IForm = {
    time?: [Dayjs, Dayjs];
    children?: (string | number)[];
    childrenCount?: string;
    bookingTimes?: [Dayjs, Dayjs];
    stayingStartDate?: Dayjs;
    plan?: number;
    numberOfAdults?: number;
    roomId?: number;
    roomTypeId?: number;
    isDayUse?: boolean;
    numberOfMale?: number | null;
    numberOfFemale?: number | null;
    numberOfOtherGenderGuest?: number | null;
};

function BookingTemporaryListMode() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [editingKey, setEditingKey] = useState<number | undefined>(undefined);
    const [bookingPrice, setBookingPrice] = useState<number>(0);
    const [bookingTmpDataTable, setBookingTmpDataTable] = useState<
        IRoomBookingSchedule[]
    >([]);
    const [currentRoomType, setCurrentRoomType] = useState<number | null>(null);
    const [currentPlan, setCurrentPlan] = useState<number | null>(null);
    const [selectedBookings, setSelectedBookings] = useState<IRoomBookingSchedule[]>([]);
    const [selectedBookingsKey, setSelectedBookingsKey] = useState<number[]>([]);
    const isAdmin = useMemo(() => {
        return checkUserPermission([UserGroup.ADMIN]);
    }, []);

    const { roomTypesDropdown, bookingTemporaryList } =
        useAppSelector(scheduleStateSelector);
    const { isFetchingRoomList, isCreatingBookingItem } =
        useAppSelector(scheduleStateSelector);
    const plansOptions = useAppSelector(planDropdownSelector);
    const { children } = useAppSelector(childrenStateSelector);
    const { control, reset, handleSubmit, setValue, setError, getValues } = useForm({
        resolver: temporaryBookingItemSchemaResolver,
    });
    const selectedHotel = useSelectedHotel();
    const { mutateAsync: mutateAsyncCreate } = useCreateGroupTempRoomBooking();
    const { mutateAsync: mutateAsyncUpdate, isPending: isPendingUpdateTempBooking } =
        useUpdateSingleTempRoomBooking();
    const { mutateAsync: mutateAsyncDelete, isPending: isPendingDeleteTempBooking } =
        useDeleteSingleTempRoomBooking([MutationKeys.deleteSingleTempRoomBooking]);
    const {
        mutateAsync: mutateAsyncDeleteGroupTempRoomBookings,
        isPending: isPendingDeleteGroupTempRoomBookings,
    } = useDeleteGroupTempRoomBookings([MutationKeys.deleteSingleTempRoomBooking]);
    const isEditing = (record: IRoomBookingSchedule) => record.id === editingKey;

    const childrenCount = (booking: IRoomBookingSchedule) => {
        return sumBy(booking.children, 'quantity');
    };

    const edit = (booking: IRoomBookingSchedule) => {
        const selectedRoomType = roomTypesDropdown.find((roomType) => {
            return roomType.id === booking.roomType?.id;
        });

        reset({
            roomTypeId: booking.roomType?.id,
            planId: booking.plan?.id,
            numberOfAdults: booking.numberOfAdults,
            numberOfMale: booking.numberOfMale || null,
            numberOfFemale: booking.numberOfFemale || null,
            numberOfOtherGenderGuest: booking.numberOfOtherGenderGuest || null,
            standardCapacity: selectedRoomType?.standardCapacity || 0,
            bookingTimes: [
                parseDate(
                    `${parseDate(booking.stayingStartDate)?.fmYYYYMMDD()} ${
                        booking.checkInTime || ''
                    }`,
                ),
                parseDate(
                    `${parseDate(booking.stayingEndDate)?.fmYYYYMMDD()} ${
                        booking.checkOutTime || ''
                    }`,
                ),
            ],
            childrenCount: childrenCount(booking),
            isDayUse: booking.isDayUse,
        });
        setEditingKey(booking.id);
        setCurrentRoomType(booking.roomType?.id);
        setCurrentPlan(booking.plan?.id);
        setBookingPrice(booking.price || 0);
    };

    const cancelEditing = (bookingId?: number | undefined) => {
        if (bookingId === -1) {
            setBookingTmpDataTable(bookingTmpDataTable.filter((item) => item.id !== -1));
        }
        setEditingKey(undefined);
        setCurrentRoomType(null);
        setCurrentPlan(null);
        setIsOpen(false);
        setBookingPrice(0);
    };

    const checkNumberOfPerson = (data: IForm) => {
        const selectedRoomType = roomTypesDropdown.find((roomType) => {
            return data.roomTypeId === roomType.id;
        });
        const numberOfAdults = Number(data.numberOfAdults) || 0;
        if (numberOfAdults > (selectedRoomType?.standardCapacity || 0)) {
            setError(
                'numberOfAdults',
                {
                    type: ErrorMessageType.MANUAL,
                    message: t('roomBooking.form.message.exceedPeople'),
                },
                { shouldFocus: true },
            );

            return false;
        }
        return true;
    };

    const _validateDayUseTimeIn24H = (dateTimes: Dayjs[]) => {
        const diff = dateTimes[1].diff(dateTimes[0], 'minutes');
        return diff > 0 && diff <= MINUTES_PER_DAY;
    };

    const _validateDayUseTimeInAfterTwoDaysBefore = (dateTimes: Dayjs[]) => {
        return dateTimes[0]?.isSameOrAfter(todayDayjs.subtract(2, 'day'), 'day');
    };

    const checkDateTime = (data: IForm) => {
        if (!data.bookingTimes) return false;
        const bookingTimes = [
            parseDate(data.bookingTimes[0]),
            parseDate(data.bookingTimes[1]),
        ];

        if (!data.isDayUse && data.bookingTimes) {
            const startDateOfStay = parseDate(
                parseDate(data.bookingTimes[0])?.fmYYYYMMDD(),
            );
            const endDateOfStay = parseDate(
                parseDate(data.bookingTimes[1])?.fmYYYYMMDD(),
            );
            const validStartDate = validateStartDateInAfterTwoDaysBefore([
                startDateOfStay,
                endDateOfStay,
            ]);
            if (!isAdmin && !validStartDate) {
                setError('stayingDate', {
                    type: ErrorMessageType.MANUAL,
                    message: t('roomBooking.form.message.startDateError'),
                });
                return;
            }

            if (!data.isDayUse) {
                const diff = data.bookingTimes
                    ? endDateOfStay.diff(startDateOfStay.add(1, 'day'))
                    : -1;
                if (diff < 0) {
                    setError(
                        CreateFacilityBookingField.BOOKING_TIMES,
                        {
                            type: ErrorMessageType.MANUAL,
                            message: t('roomBooking.form.message.datePeriodError'),
                        },
                        { shouldFocus: true },
                    );
                    return false;
                }
            } else {
                const validIn24H = _validateDayUseTimeIn24H(bookingTimes);
                if (!validIn24H) {
                    setError(CreateFacilityBookingField.BOOKING_TIMES, {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.form.message.time24hPeriodError'),
                    });
                    return;
                }
            }
        }

        if (data.isDayUse && data.bookingTimes) {
            const validAfterTwoDaysBefore =
                _validateDayUseTimeInAfterTwoDaysBefore(bookingTimes);
            if (!isAdmin && !validAfterTwoDaysBefore) {
                setError(CreateFacilityBookingField.BOOKING_TIMES, {
                    type: ErrorMessageType.MANUAL,
                    message: t('roomBooking.form.message.checkInTimeError'),
                });
                return;
            }

            const validIn24H = _validateDayUseTimeIn24H(bookingTimes);
            if (!validIn24H) {
                setError(CreateFacilityBookingField.BOOKING_TIMES, {
                    type: ErrorMessageType.MANUAL,
                    message: t('roomBooking.form.message.time24hPeriodError'),
                });
                return;
            }
        }
        return true;
    };

    const _getBookingPrice = async (additionalField?: {
        [key: string]: string | number;
    }) => {
        const {
            roomTypeId,
            planId,
            bookingTimes,
            numberOfAdults,
            children: _children,
        } = getValues();
        if (!roomTypeId || !planId || !numberOfAdults || bookingTimes?.length !== 2)
            return false;
        const query: IGetBookingPrice = {
            roomTypeId: roomTypeId,
            startDateOfStay: parseDate(bookingTimes[0])?.fmYYYYMMDD('-'),
            endDateOfStay: parseDate(bookingTimes[1])?.fmYYYYMMDD('-'),
            numberOfAdults: Number(numberOfAdults),
            planId: planId,
            childrenTypeQuantities: _children?.map((val: string, index: number) => ({
                childrenTypeId: children[index].id,
                quantity: Number(val) || 0,
            })),
            ...(additionalField || {}),
        };
        const response = await dispatch(getBookingPrice(query));
        if (getBookingPrice.fulfilled.match(response)) {
            const { data } = response.payload;
            if (!data) {
                notification.error({
                    message:
                        response?.payload?.message ||
                        t('roomBooking.createBooking.message.calculateAmountError'),
                });
                return false;
            }
            setBookingPrice(data.amount || 0);
            return true;
        }
        return true;
    };

    const saveBookingTemporaryItem = (booking: IRoomBookingSchedule) => {
        handleSubmit(async (data) => {
            const {
                roomTypeId,
                planId,
                bookingTimes,
                numberOfAdults,
                numberOfMale,
                numberOfFemale,
                numberOfOtherGenderGuest,
                roomId,
            } = data;
            const plan = plansOptions.find((item) => item.value === planId);
            if (plan && plan?.type === PlanType.DAY_USE) {
                data.isDayUse = true;
            } else {
                data.isDayUse = false;
            }
            if (!checkNumberOfPerson(data)) return;
            if (
                !validateGenderBreakdownOfGuest({
                    numberOfAdults: data.numberOfAdults?.toString() || '',
                    numberOfMale: data.numberOfMale?.toString() || '',
                    numberOfFemale: data.numberOfFemale?.toString() || '',
                    numberOfOtherGenderGuest:
                        data.numberOfOtherGenderGuest?.toString() || '',
                })
            ) {
                setError(
                    'numberOfAdults',
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.form.message.genderBreakdownError'),
                    },
                    { shouldFocus: true },
                );
                return;
            }
            if (!checkDateTime(data)) return;

            const _children: IChildBooking[] = [];
            forEach(data.children, (val, index: number) => {
                _children.push({
                    id: null,
                    quantity: Number(val),
                    typeId: children[index].id,
                });
            });
            // check booking price
            const check = await _getBookingPrice();
            if (!check) {
                setError('plan', {
                    type: ErrorMessageType.MANUAL,
                    message: t('roomBooking.form.planInvalidPrice'),
                });
                return;
            }

            const _booking: IRoomBookingSchedule = {
                ...booking,
                numberOfAdults: Number(numberOfAdults) || null,
                numberOfMale: Number(numberOfMale) || 0,
                numberOfFemale: Number(numberOfFemale) || 0,
                numberOfOtherGenderGuest: Number(numberOfOtherGenderGuest) || 0,
                stayingStartDate: parseDate(bookingTimes?.[0])?.fmYYYYMMDD('-') || '',
                stayingEndDate: parseDate(bookingTimes?.[1])?.fmYYYYMMDD('-') || '',
                checkInTime: data.isDayUse
                    ? parseDate(bookingTimes?.[0])?.fmHHmm()
                    : booking.checkInTime,
                checkOutTime: data.isDayUse
                    ? parseDate(bookingTimes?.[1])?.fmHHmm()
                    : booking.checkOutTime,
                children: _children,
                isDayUse: data.isDayUse,
                plan: {
                    id: planId || null,
                    name: plan?.label || '',
                },
                room: {
                    id: roomId || null,
                    name: '',
                },
                roomType: {
                    id: roomTypeId || null,
                    name:
                        roomsTypeOptions.find((item) => item.value === roomTypeId)
                            ?.label || '',
                },
            };
            if (+roomTypeId === booking.roomType?.id) {
                Object.assign(_booking, {
                    room: {
                        id: booking.room?.id,
                        name: '',
                    },
                });
            }

            if (booking.id === -1) {
                if (selectedHotel?.useNewTempRoomBookingLib) {
                    const tempRoomBookings =
                        TempRoomBookingHelper.buildCreateTempRoomBookingBody([_booking]);
                    await _createBookingItemV2(_booking, tempRoomBookings);
                } else {
                    _createBookingItem(_booking);
                }
            } else {
                if (selectedHotel?.useNewTempRoomBookingLib) {
                    const updateTempRoomBookingBody =
                        TempRoomBookingHelper.buildUpdateSingleTempRoomBookingBody(
                            _booking,
                        );
                    await _updateTemporaryBookingItemV2(
                        _booking,
                        updateTempRoomBookingBody,
                    );
                } else {
                    _updateTemporaryBookingItem(_booking);
                }
            }
        })();
    };

    const _updateBookingList = (
        data: ICreateOrUpdateBookingTmp,
        _booking: IRoomBookingSchedule,
    ) => {
        const { id, roomBookingItemChildrenTypes } = data;
        const children = _booking.children.map((child) => {
            const childId =
                roomBookingItemChildrenTypes?.find(
                    (childAmount) => childAmount.childrenTypeId === child.typeId,
                )?.id || null;
            return {
                ...child,
                id: childId,
            };
        });
        dispatch(
            updateBookingItemAndTmp({
                bookings: [{ ..._booking, id, children }],
            }),
        );
        dispatch(getTemporaryBookingItemList());
    };

    const showConfirmOverbookingDialogV2 = (
        _booking: IRoomBookingSchedule,
        tempRoomBookings: ICreateGroupTempRoomBooking,
    ) => {
        showConfirm({
            title: t('roomBooking.form.overbooking.title'),
            cancelText: t('roomBooking.form.popupCancel.buttonCancelText'),
            okText: t('roomBooking.form.popupCancel.buttonDeleteText'),
            async onOk() {
                _createBookingItemV2(_booking, tempRoomBookings, true);
            },
        });
    };

    const _updateTemporaryBookingItemV2 = async (
        booking: IRoomBookingSchedule,
        updateTempRoomBookingBody: IUpdateSingleTempRoomBooking,
    ) => {
        try {
            const data = await mutateAsyncUpdate({
                tempRoomBooking: updateTempRoomBookingBody,
                isAcceptOverbooking: false,
            });
            const updatedBookingData = TempRoomBookingHelper.buildUpdateBookingData(data);
            _updateBookingList(updatedBookingData, booking);
            dispatch(getStatisticByDate());
            cancelEditing();
        } catch (error) {
            notification.error({
                message: (error as IBodyResponseTsRest<RoomBooking>).message,
            });
        }
    };

    const _updateTemporaryBookingItem = async (booking: IRoomBookingSchedule) => {
        const response = await dispatch(updateBookingItem({ booking }));
        if (updateBookingItem.fulfilled.match(response)) {
            if (response.payload?.success) {
                notification.success({
                    message: t('roomBooking.schedule.message.updateTmpSuccess'),
                });
                _updateBookingList(
                    response.payload.data as ICreateOrUpdateBookingTmp,
                    booking,
                );
                cancelEditing();
                return;
            }
            notification.error({
                message: t('common.somethingWentWrong'),
                description: response.payload?.errors?.[0]?.message || '',
            });

            (response.payload?.errors || []).forEach((error) => {
                if (
                    error.key === CreateFacilityBookingField.START_DATE_OF_STAY ||
                    error.key === CreateFacilityBookingField.END_DATE_OF_STAY ||
                    error.key === CreateFacilityBookingField.DATE_OF_STAY ||
                    error.key === CreateFacilityBookingField.CHECK_IN_TIME ||
                    error.key === CreateFacilityBookingField.CHECK_OUT_TIME
                ) {
                    setError(
                        CreateFacilityBookingField.BOOKING_TIMES,
                        { type: ErrorMessageType.MANUAL, message: error.message },
                        { shouldFocus: true },
                    );
                }
                setError(
                    error.key as keyof IForm,
                    { type: ErrorMessageType.MANUAL, message: error.message },
                    { shouldFocus: true },
                );
            });
        }
        return;
    };

    const _createBookingItemV2 = async (
        _booking: IRoomBookingSchedule,
        tempRoomBookings: ICreateGroupTempRoomBooking,
        isAcceptOverbooking = false,
    ) => {
        try {
            const data = await mutateAsyncCreate({
                tempRoomBookings,
                isAcceptOverbooking,
            });
            cancelEditing();
            const updatedBookingData = TempRoomBookingHelper.buildUpdateBookingData(
                data[0],
            );
            dispatch(getStatisticByDate());
            _updateBookingList(updatedBookingData, _booking);
        } catch (error) {
            if (error instanceof OverBookingException) {
                showConfirmOverbookingDialogV2(_booking, tempRoomBookings);
            } else {
                notification.error({
                    message: (error as IBodyResponseTsRest<RoomBooking[]>).message,
                });
            }
        }
    };

    const _createBookingItem = async (
        booking: IRoomBookingSchedule,
        isAcceptOverbooking = false,
    ) => {
        const response = await dispatch(
            createBookingItem({ booking, isAcceptOverbooking }),
        );
        if (createBookingItem.fulfilled.match(response)) {
            if (response.payload?.success) {
                cancelEditing();
                _updateBookingList(response.payload.data, booking);
                return;
            }
            notification.error({
                message: t('common.somethingWentWrong'),
                description: response.payload?.errors?.[0]?.message || '',
            });

            (response.payload?.errors || []).forEach((error) => {
                if (
                    error.key === CreateFacilityBookingField.START_DATE_OF_STAY ||
                    error.key === CreateFacilityBookingField.END_DATE_OF_STAY ||
                    error.key === CreateFacilityBookingField.DATE_OF_STAY ||
                    error.key === CreateFacilityBookingField.CHECK_IN_TIME ||
                    error.key === CreateFacilityBookingField.CHECK_OUT_TIME
                ) {
                    setError(
                        CreateFacilityBookingField.BOOKING_TIMES,
                        { type: ErrorMessageType.MANUAL, message: error.message },
                        { shouldFocus: true },
                    );
                }
                setError(
                    error.key as keyof IForm,
                    { type: ErrorMessageType.MANUAL, message: error.message },
                    { shouldFocus: true },
                );
            });
        }
    };

    useMemo(() => {
        setBookingTmpDataTable([...bookingTemporaryList]);
    }, [bookingTemporaryList]);

    const onClickButtonAdd = () => {
        setEditingKey(-1);
        setBookingTmpDataTable([
            ...bookingTemporaryList,
            {
                ...getBookingScheduleDefault(),
                id: -1,
            },
        ]);
        reset({
            roomTypeId: null,
            planId: null,
            numberOfAdults: null,
            bookingTimes: null,
            standardCapacity: 0,
        });
    };

    const onConfirmDeletion = async (booking: IRoomBookingSchedule) => {
        const handleSuccess = () => {
            dispatch(removeBookingTmp({ bookings: [booking] }));
            setSelectedBookings((prev) => prev.filter((item) => item.id !== booking.id));
            dispatch(getStatisticByDate());
            setEditingKey(undefined);
            notification.success({
                message: t('roomBooking.schedule.message.deleteTmpSuccess'),
            });
        };

        const handleError = () => {
            notification.error({
                message: t('roomBooking.schedule.message.deleteTmpFailed'),
            });
        };

        if (selectedHotel?.useNewTempRoomBookingLib) {
            try {
                await mutateAsyncDelete(booking.id);
                handleSuccess();
            } catch (error) {
                handleError();
            }
        } else {
            const response = await dispatch(deleteBookingItem(booking.id));
            if (
                deleteBookingItem.fulfilled.match(response) &&
                response.payload?.success
            ) {
                handleSuccess();
            } else {
                handleError();
            }
        }
    };

    const onConfirmBulkDeletion = async (bookings: IRoomBookingSchedule[]) => {
        const handleSuccess = (deletedBookings: IRoomBookingSchedule[]) => {
            notification.success({
                message: t('roomBooking.schedule.message.deleteTmpSuccess'),
            });
            dispatch(removeBookingTmp({ bookings: deletedBookings }));
            setSelectedBookings((prev) =>
                prev.filter((item) => !deletedBookings.includes(item)),
            );
            setSelectedBookingsKey((prev) =>
                prev.filter(
                    (key) => !deletedBookings.some((booking) => booking.id === key),
                ),
            );
            setEditingKey(undefined);
            dispatch(getStatisticByDate());
        };

        const handleError = () => {
            notification.error({
                message: t('roomBooking.schedule.message.deleteTmpFailed'),
            });
        };

        const deleteBookingsIndividually = async () => {
            const successfulBookings: IRoomBookingSchedule[] = [];

            for (const booking of bookings) {
                try {
                    const response = await dispatch(deleteBookingItem(booking.id));

                    if (
                        deleteBookingItem.fulfilled.match(response) &&
                        response.payload?.success
                    ) {
                        successfulBookings.push(booking);
                    } else {
                        handleError();
                    }
                } catch (error) {
                    handleError();
                }
            }

            if (successfulBookings.length) {
                handleSuccess(successfulBookings);
            }
        };

        try {
            if (selectedHotel?.useNewTempRoomBookingLib) {
                await mutateAsyncDeleteGroupTempRoomBookings(
                    bookings.map((booking) => booking.id),
                );
                handleSuccess(bookings);
            } else {
                await deleteBookingsIndividually();
            }
        } catch (error) {
            handleError();
        }
    };

    const deleteTmpBooking = (booking: IRoomBookingSchedule) => {
        ModalConfirmDeletion({
            deletedItems: [],
            buttonCancelText: t('common.buttonCancelText'),
            buttonDeleteText: t('common.buttonDeleteText'),
            okButtonProps: { danger: true },
            onClickButtonDelete: () => onConfirmDeletion(booking),
        });
    };

    const bulkDeleteTmpBooking = (bookings: IRoomBookingSchedule[]) => {
        ModalConfirmDeletion({
            deletedItems: bookings.map((booking) => booking.id.toString()),
            buttonCancelText: t('common.buttonCancelText'),
            buttonDeleteText: t('common.buttonDeleteText'),
            okButtonProps: { danger: true },
            onClickButtonDelete: () => onConfirmBulkDeletion(bookings),
        });
    };

    const roomsTypeOptions = useMemo(() => {
        if (currentPlan) {
            const plan = plansOptions.find((option) => {
                return option.value === currentPlan;
            });
            return roomTypesDropdown
                .filter((roomType) => {
                    return plan?.roomTypeIds?.includes(roomType.id);
                })
                .map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
        }

        return roomTypesDropdown.map((item) => ({
            label: item.name,
            value: item.id,
        }));
    }, [roomTypesDropdown, currentPlan]);

    const planDropDownOptions = useMemo(() => {
        if (currentRoomType)
            return plansOptions.filter((plan) => {
                return plan.roomTypeIds?.includes(currentRoomType);
            });
        return plansOptions;
    }, [plansOptions, currentRoomType]);

    useEffect(() => {
        const selectedRoomType = roomTypesDropdown.find((roomType) => {
            return roomType.id === currentRoomType;
        });

        setValue('standardCapacity', selectedRoomType?.standardCapacity || 0);
    }, [currentRoomType]);

    const changeRoomType = (value: number) => {
        setCurrentRoomType(value);
        setValue('roomTypeId', value);
        setValue('roomId', null);
        _getBookingPrice();
    };

    const rowSelection = {
        selectedRowKeys: selectedBookingsKey,
        onChange: (
            _: React.Key[],
            selectedRows: IRoomBookingSchedule[],
            info: { type: string },
        ) => {
            const filteredRows = selectedRows.filter(
                (row) => row.id !== null && row.children !== undefined,
            );
            const filteredRowsKey = filteredRows.map((row) => row.id || 0);
            setSelectedBookings(filteredRows);
            setSelectedBookingsKey(filteredRowsKey);
            if (
                info.type === 'all' &&
                filteredRows.length === selectedBookingsKey.length
            ) {
                setSelectedBookingsKey([]);
                setSelectedBookings(filteredRows);
            }
        },
        onSelectAll: (selected: boolean) => {
            if (selected) {
                setSelectedBookingsKey([]);
                setSelectedBookings([]);
            }
        },
    };

    useEffect(() => {
        const bookings = selectedBookings.filter((booking) =>
            selectedBookingsKey.includes(booking.id || 0),
        );
        setSelectedBookings(bookings);
    }, [selectedBookingsKey]);

    const sortedTempBookings = useMemo(() => {
        return [...bookingTmpDataTable].sort((a, b) => a.id - b.id);
    }, [bookingTmpDataTable]);

    return (
        <div className="booking-temporary-list-mode">
            <Form>
                <Table
                    dataSource={sortedTempBookings}
                    rowClassName="editable-row"
                    className="table-scrollbar"
                    scroll={{ x: 1300, y: 220 }}
                    pagination={false}
                    rowKey="id"
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    loading={
                        isFetchingRoomList ||
                        isPendingDeleteTempBooking ||
                        isPendingUpdateTempBooking
                    }
                    footer={() => (
                        <Button
                            type="dashed"
                            onClick={onClickButtonAdd}
                            className="button-add"
                            block
                            disabled={editingKey !== undefined}
                        >
                            <PlusCircleOutlined className="right-register-form-icon" />
                            <span>{t('facility.create.buttons.addFacilities')}</span>
                        </Button>
                    )}
                >
                    <Column
                        align="center"
                        width={60}
                        title={'#'}
                        key={'index'}
                        fixed="left"
                        render={(booking: IRoomBookingSchedule, item, index) => {
                            const editable = isEditing(booking);
                            return (
                                <div
                                    className={editable ? 'temporary-index-editing' : ''}
                                >
                                    {index + 1}
                                </div>
                            );
                        }}
                    />
                    <Column
                        title={`${t('roomBooking.form.scheduleBooking.roomType')}`}
                        width={'180px'}
                        key={'roomType'}
                        render={(booking: IRoomBookingSchedule) => {
                            const editable = isEditing(booking);
                            return (
                                <>
                                    {editable ? (
                                        <SingleSelect
                                            label=""
                                            placeholder={t(
                                                'roomBooking.form.scheduleBooking.roomType',
                                            )}
                                            name={`roomTypeId`}
                                            options={roomsTypeOptions}
                                            control={control}
                                            onChange={(value) => changeRoomType(value)}
                                        />
                                    ) : (
                                        <TextTruncate
                                            text={booking.roomType?.name || ''}
                                        />
                                    )}
                                </>
                            );
                        }}
                    />
                    <Column
                        title={`${t('roomBooking.form.scheduleBooking.plan')}`}
                        key={'plan'}
                        width={'180px'}
                        render={(booking: IRoomBookingSchedule) => {
                            const editable = isEditing(booking);
                            return (
                                <>
                                    {editable ? (
                                        <SingleSelect
                                            label=""
                                            placeholder={t(
                                                'roomBooking.form.scheduleBooking.plan',
                                            )}
                                            name={`planId`}
                                            options={planDropDownOptions}
                                            control={control}
                                            onChange={(value) => {
                                                setCurrentPlan(value);
                                                setValue('planId', value);
                                                _getBookingPrice();
                                            }}
                                        />
                                    ) : (
                                        <TextTruncate text={booking.plan?.name || ''} />
                                    )}
                                </>
                            );
                        }}
                    />
                    <Column
                        title={`${t('roomBooking.form.scheduleBooking.adult')}`}
                        key={'numberOfAdult'}
                        width={'150px'}
                        render={(booking: IRoomBookingSchedule) => {
                            const editable = isEditing(booking);
                            return (
                                <>
                                    {editable ? (
                                        <InputAdult
                                            label=""
                                            control={control}
                                            setValue={setValue}
                                            isHorizontal={true}
                                            isHideLabel={true}
                                            className="booking-form-input-adult"
                                            fieldName={InputAdultFieldName}
                                            autoSetTotalGuest={true}
                                            onchange={(value) => {
                                                setValue('numberOfAdults', value);
                                                _getBookingPrice();
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <span>{booking.numberOfAdults || ''}</span>
                                            <span>
                                                {t(
                                                    'roomBooking.schedule.genderBreakdown',
                                                    {
                                                        male: booking.numberOfMale || 0,
                                                        female:
                                                            booking.numberOfFemale || 0,
                                                        other:
                                                            booking.numberOfOtherGenderGuest ||
                                                            0,
                                                    },
                                                )}
                                            </span>
                                        </>
                                    )}
                                </>
                            );
                        }}
                    />
                    <Column
                        title={`${t('roomBooking.form.scheduleBooking.child')}`}
                        key={'numberOfChild'}
                        width="200px"
                        render={(booking: IRoomBookingSchedule) => {
                            const editable = isEditing(booking);
                            return (
                                <>
                                    {editable ? (
                                        <>
                                            <div>
                                                <InputText
                                                    label=""
                                                    name="childrenCount"
                                                    placeholder={t(
                                                        'roomBooking.form.scheduleBooking.child',
                                                    )}
                                                    control={control}
                                                    defaultValue={
                                                        booking?.children?.length
                                                            ? childrenCount(booking)
                                                            : undefined
                                                    }
                                                />

                                                <div
                                                    className="input-overlay"
                                                    onClick={() => {
                                                        setIsOpen(!isOpen);
                                                    }}
                                                >
                                                    <DownOutlined
                                                        className={classNames({
                                                            'expand-icon': true,
                                                            active: isOpen,
                                                            deactive: !isOpen,
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={classNames('child-content', {
                                                    open: isOpen,
                                                    close: !isOpen,
                                                })}
                                            >
                                                <ChildrenInput
                                                    isOpen={isOpen}
                                                    control={control}
                                                    name={(index) => `children.${index}`}
                                                    defaultValue={getChildrenObject(
                                                        booking.children,
                                                    )}
                                                    onChange={(value, total) => {
                                                        setValue(
                                                            'childrenCount',
                                                            total.toString(),
                                                        );
                                                        _getBookingPrice();
                                                    }}
                                                    className="booking-form-children-input"
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <span>{childrenCount(booking) || 0}</span>
                                    )}
                                </>
                            );
                        }}
                    />
                    <Column
                        title={`${t(
                            'roomBooking.form.scheduleBooking.lengthOfStay',
                        )} (${t('common.standardTimeTitle')})`}
                        key={'bookingTimes'}
                        render={(booking: IRoomBookingSchedule) => {
                            const editable = isEditing(booking);
                            return (
                                <>
                                    {editable ? (
                                        <RangePicker
                                            label=""
                                            placeholder={[
                                                t(
                                                    'roomBooking.form.placeholder.lengthOfStay.start',
                                                ),
                                                t(
                                                    'roomBooking.form.placeholder.lengthOfStay.end',
                                                ),
                                            ]}
                                            showTime={true}
                                            name={`bookingTimes`}
                                            control={control}
                                            allowClear={false}
                                            format={
                                                DateFormat.YYYY_MM_DD_HYPHEN_HH_MM_COLON
                                            }
                                            disabledDate={(current) => {
                                                // Allow ADMIN user to select any dates
                                                if (isAdmin) return false;

                                                return current.isBefore(
                                                    todayDayjs.subtract(2, 'day'),
                                                    'day',
                                                );
                                            }}
                                            onChange={(values) => {
                                                setValue('bookingTimes', values);
                                                _getBookingPrice();
                                            }}
                                        />
                                    ) : (
                                        <span>
                                            {`${parseDate(
                                                booking.stayingStartDate,
                                            )?.fmYYYYMMDD('-')} ${
                                                booking.checkInTime || ''
                                            }`}
                                            <MinusOutlined className="minus-icon" />
                                            {`${parseDate(
                                                booking.stayingEndDate,
                                            )?.fmYYYYMMDD('-')} ${
                                                booking.checkOutTime || ''
                                            }`}
                                        </span>
                                    )}
                                </>
                            );
                        }}
                    />
                    <Column
                        title={`${t('roomBooking.form.scheduleBooking.subTotalTitle')}`}
                        key={'subTotal'}
                        width={'100px'}
                        render={(booking: IRoomBookingSchedule) => {
                            const editable = isEditing(booking);
                            return editable ? (
                                <span>{formatMoney(bookingPrice || 0)}</span>
                            ) : (
                                <span>{formatMoney(booking.price || 0)}</span>
                            );
                        }}
                    />
                    <Column
                        title={
                            <>
                                <Button
                                    shape="circle"
                                    type="text"
                                    icon={<DeleteOutlined />}
                                    loading={isPendingDeleteGroupTempRoomBookings}
                                    onClick={() => bulkDeleteTmpBooking(selectedBookings)}
                                    disabled={selectedBookings.length === 0}
                                />
                                ({selectedBookingsKey.length})
                            </>
                        }
                        key={'action'}
                        width={'100px'}
                        fixed="right"
                        render={(booking: IRoomBookingSchedule) => {
                            const editable = isEditing(booking);
                            return (
                                <>
                                    {editable ? (
                                        <div className="save-cancel-group-button">
                                            <Button
                                                type="primary"
                                                size="small"
                                                onClick={() =>
                                                    saveBookingTemporaryItem(booking)
                                                }
                                                loading={isCreatingBookingItem}
                                                icon={<CheckOutlined />}
                                            />
                                            <Button
                                                onClick={() => cancelEditing(booking.id)}
                                                size="small"
                                                icon={<CloseOutlined />}
                                            />
                                        </div>
                                    ) : (
                                        <span>
                                            <Button
                                                shape="circle"
                                                type="text"
                                                disabled={editingKey !== undefined}
                                                onClick={() => edit(booking)}
                                                icon={<EditOutlined />}
                                            />
                                            <Button
                                                shape="circle"
                                                type="text"
                                                icon={<DeleteOutlined />}
                                                onClick={() => deleteTmpBooking(booking)}
                                            />
                                        </span>
                                    )}
                                </>
                            );
                        }}
                    />
                </Table>
            </Form>
        </div>
    );
}

export default BookingTemporaryListMode;
